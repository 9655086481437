import { createSlice } from "@reduxjs/toolkit";


export const calendarSlice = createSlice({
    name: "calendar",
    initialState: {
        calendars:[],
        update: false,
        trainer:[]
    },
    reducers: {
        setCalendars: (state, action) => {
            state.calendars =  action.payload
        },
        setTrainer : (state,action) =>{
            state.trainer = action.payload
        }
    }
})



export const  {
    setCalendars,
    setTrainer
} = calendarSlice.actions



export  default calendarSlice.reducer