import { createSlice } from "@reduxjs/toolkit";


export const machineSlice = createSlice({
    name: "machine",
    initialState: {
        machine: {
            name: "",
            group: "",
            description: "",
            url_image: ""
        },
        machines: [],
        GrupoMuscular:[],
        update: false
    },
    reducers: {
        setMachine: (state, action) => {
            state.machine[action.payload.type] = action.payload.value
            console.log(state.machine)
        },

        setClean: (state, action) => {
            state.machine = {
                name: "",
                group: "",
                description: "",
                url_image: ""
            }
        },
        setCleanImage: (state, action) =>{
            state.machine.url_image =""
        },
        setMachineComplete : (state, action) =>{
            state.machine = action.payload
        },
        setMachines: (state, action) => {
            state.machines = action.payload
        },
        setUpdate: (state, action) => {
            state.update = action.payload
        },
        setGrupoMuscular :(state,action) =>{
            state.GrupoMuscular = action.payload
        }
    }
})



export const  {
    setMachine,
    setClean,
    setMachines,
    setMachineComplete,
    setUpdate,
    setGrupoMuscular,
    setCleanImage
} = machineSlice.actions



export  default machineSlice.reducer