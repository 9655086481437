import React from 'react'
// import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Card, CardContent, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import Button from '@mui/material/Button';
import Background from '../../assets/background.png'
import Logo from '../../assets/logoIronBody.png'
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Resetpassword = () => {

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box sx={{height: '100vh', display: 'flex', alignItems: 'center',  backgroundImage: `url(${Background})`, backgroundSize: 'cover' }}>

    <Card variant="outlined"  sx={{  margin:'auto', justifyContent: 'center', borderRadius: '10px'}}>

      <CardContent   sx={{  display: 'flex', flexDirection: 'column', width: '380px'}}>    
        
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <img src={Logo} height='65px' width='145px' alt='Logo IronBody' />
        </Box>

        <Box mt={'20px'}  mb={'24px'} sx={{ alignContent: 'center', justifyContent: 'center'}}>
        <Typography sx={{fontWeight: 600, fontSize: '25px',lineHeight: '150%', color: 'rgba(76, 78, 100, 0.87)'}}>Restablecer Clave</Typography>
        <Typography sx={{fontWeight: 400, fontSize: '16px', lineHeight: '150%', color: 'rgba(76, 78, 100, 0.68)'}}>Su nueva clave debe ser diferente de las utilizadas anteriormente</Typography>
        </Box>

        <Box >
          
  
          <FormControl fullWidth variant="outlined" sx={{marginBottom: '16px'}}>
            <InputLabel htmlFor="outlined-adornment-password">Nueva Clave</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"                  
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Nueva Clave"              
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{marginBottom: '16px'}}>
            <InputLabel htmlFor="outlined-adornment-password">Confirmar Clave</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"                  
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirmar Clave"
                            
            />
          </FormControl>
        </Box>

        <Button variant="contained" sx={{backgroundColor:'#F3EC46', color: '#000', marginBottom:'16px','&:hover':{backgroundColor:'rgba(243, 236, 70, 0.95)'}}}>Restablecer</Button>
        <Button startIcon={<ArrowBackIosNewOutlinedIcon />} href='/auth' variant="text" size='small' sx={{ textTransform: 'capitalize', fontWeight: 400, fontSize: '16px',lineHeight: '150%', color: 'rgba(76, 78, 100, 0.87)'}}>Volver al inicio de sesión</Button>
        
    
      </CardContent>
    </Card>
  </Box>
  )
}


export default Resetpassword