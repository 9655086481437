//packages
import React, { memo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { setLogout } from "../domain/auth/reducer/auth.reducer";
import { useSelector, useDispatch } from "react-redux";


//Icons
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"; //calendario
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined"; //clientes-entrenadores
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined"; //notificaciones
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined"; //maquinas
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined"; //Guias
import Logout from "@mui/icons-material/Logout";
import { auth } from "../infra/fifebase"

//Images
import Logo from "../assets/logoIronBody.png";


const drawerWidth = 260;

const listIconsAdmin = [
  {
    label: "Clientes",
    icon: <PersonOutlineOutlinedIcon />,
  },
  {
    label: "Calendario",
    icon: <CalendarMonthOutlinedIcon />,
  },
  {
    label: "Entrenadores",
    icon: <PersonOutlineOutlinedIcon />,
  },
  {
    label: "Guias",
    icon: <FactCheckOutlinedIcon />,
  },
  {
    label: "Notificaciones",
    icon: <NotificationsNoneOutlinedIcon />,
  },
  {
    label: "Maquinas",
    icon: <FitnessCenterOutlinedIcon />,
  },
];



const listIconsUser = [
  {
    label: "Clientes",
    icon: <PersonOutlineOutlinedIcon />,
  },
  {
    label: "Guias",
    icon: <FactCheckOutlinedIcon />,
  },
  {
    label: "Maquinas",
    icon: <FitnessCenterOutlinedIcon />,
  },
];



const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));


function PersistentDrawerLeft({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const dispatch = useDispatch()
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openIcon = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    auth.signOut()
    localStorage.clear()
    dispatch(setLogout())
  
  };

  const { type } = useSelector((state) => state.login);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ backgroundColor: "secondary.main" }}>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openIcon ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openIcon ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
          </IconButton>
        </Toolbar>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openIcon}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Divider />

          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Cerrar Sesion
          </MenuItem>
        </Menu>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "secondary.dark",
            color: "white",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", margin: "10px 0px"}}>
            <Box>
              <img src={Logo} alt="logo" height={"83"} />
            </Box>
            <Typography
              variant="h6"
              marginX={1}
              noWrap
              component="div"
              sx={{
                fontWeight: 900,
                fontSize: "18px",
                lineHeight: "24px",
              }}
            >
              IronBodyNeiva
            </Typography>
          </Box>
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              color: "secondary.main",
              "&:hover": { backgroundColor: "secondary.light" },
            }}
          >
            {theme.direction === "ltr" ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ backgroundColor: "secondary.main" }} />

        <List sx={{ margin: "4px 4px" }}>
          
          {  (type === "Entrenador" ? listIconsUser : listIconsAdmin ).map((option) => (
            <ListItem
              key={option.label}
              disablePadding
              sx={{
                "&:hover": {
                  backgroundColor: "primary.main",
                  borderRadius: "8px",
                  color: "secondary.dark",
                },
              }}
            >
              <ListItemButton component={Link} to={`/${option.label}`}>
                <ListItemIcon sx={{ color: "secondary.main", minWidth: 32 }}>
                  {option.icon}
                </ListItemIcon>
                <ListItemText primary={option.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}

export default memo(PersistentDrawerLeft)