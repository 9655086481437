import React, { memo } from "react";
import { Box, Typography, TextField, MenuItem, Button } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import {
  setClean,
  setNotification,
  setType,
  setCleanImage
} from "./reducer/notification.reducer";
import {
  getQuery,
  storage,
  uploadBytes,
  getDownloadURL
} from "../infra/fifebase";
import axios from "axios";
// import Swal from "sweetalert2";
import imgUser from "../assets/Avatar.png";
import { v1 as uuidv1 } from "uuid";


const Notifications = () => {
  const dispatch = useDispatch();


  const [cover, setCover] = React.useState(null);
  const { notification } = useSelector((state) => state.notification);

  const isChecked = (text) => {
    return notification.type.findIndex((type) => type === text) !== -1;
  };

  const tipoUsuario = [
    {
      value: "1",
      label: "pago",
    },
    {
      value: "2",
      label: "sin pagar",
    },
    {
      value: "3",
      label: "congelado",
    },
    {
      value: "4",
      label: "ausente",
    },
    {
      value: "5",
      label: "Todos los usuarios",
    },
  ];

  const keyTipo = {
    "1": "pago",
    "2": 'sin pagar',
    "3": 'congelado',
    "4": 'ausente',
  }



  const registerNotification = async () => {
    const where = [];
    if (notification.user !== "3") {
      where.push({
        item: "state",
        condition: "==",
        value: keyTipo[notification.user],
      });
    }
    const users = await getQuery("users", null, where, {
      isStart: true,
      order: "uid"
    }, 10000);
    const token = [];
    const phone = [];
    let url_cover = ""
    users.map((user) => {
      if (user.token) {
        token.push(user.token)
      }

      if (user.phone) {
        phone.push(user.phone)
      }
    });

    if(cover){
      const id = uuidv1();
      const snapshotCover = await uploadBytes(
        storage("flayers/flayers-" + id + ".png"),
        cover
      );
      url_cover = await getDownloadURL( storage(snapshotCover.metadata.fullPath))
    }


    const { type, message } = notification

    const request = {
      type,
      toPush: token,
      toWp: phone,
      message,
      url_cover
    }



    try {
      const { data } = await axios.post("http://localhost:3002/notification-user", request)
      console.log(data)
      alert("notificaciones enviadas")
    } catch (e) {
      alert("error en notificaciones")
    }


  }

  return (
    <Box>
      <Typography variant="h5">Notificaciones</Typography>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "15px",
          color: "rgba(76, 78, 100, 0.68)",
          marginBottom: "12px",
          letterSpacing: "0.4px",
        }}
      >
        Aquí podras enviar notificaciones a tus clientes
      </Typography>

      <Box
        marginBottom={"12px"}
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "45ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
          <FormLabel component="legend">Tipo de notificaciones</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked("push")}
                  onChange={() => {
                    dispatch(
                      setType({
                        active: isChecked("push"),
                        type: "push",
                      })
                    );
                  }}
                  name="push"
                />
              }
              label="push"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked("wp")}
                  onChange={() => {
                    dispatch(
                      setType({
                        active: isChecked("wp"),
                        type: "wp",
                      })
                    );
                  }}
                  name="wpp"
                />
              }
              label="Whatsapp"
            />
          </FormGroup>
        </FormControl>

        <div>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "15px",
              color: "rgba(76, 78, 100, 0.68)",
              marginY: "12px",
              letterSpacing: "0.4px",
              marginLeft: "10px",
            }}
          >
            Mensaje
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Escribe aquí el mensaje..."
            multiline
            rows={5}
            maxRows={10}
            sx={{
              width: "91ch",
              marginLeft: "10px",
              borderRadius: "6px",
            }}
            value={notification.message}
            onChange={(e) => {
              dispatch(
                setNotification({
                  type: "message",
                  value: e.target.value,
                })
              );
            }}
            name="Outlined"
          />
          <TextField
            id="outlined-select-currency"
            select
            label="Usuario"
            defaultValue="Cliente"
            helperText="Por favor seleccione a que usuario va dirigido"
            onChange={(e) => {
              dispatch(
                setNotification({
                  type: "user",
                  value: e.target.value,
                })
              );
            }}
            value={notification.user}
          >
            {tipoUsuario.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {isChecked("wp") ?
          (<Box sx={{ display: "flex", gap: 1, marginBottom: "24px" }}>
            <Box sx={{ width: 120, height: 120, marginRight: "20px" }}>
              <img
                width={120}
                height={120}

                src={!cover ? (notification.url_cover === "" ? imgUser : notification.url_cover) : URL.createObjectURL(cover)}
                alt="user"
              />
            </Box>

            <Box
              sx={{
                marginBottom: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Box>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    backgroundColor: "primary.main",
                    color: "secondary.dark",
                    marginRight: "16px",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    "&:hover": {
                      backgroundColor: "rgba(243, 236, 70, 0.95)",
                    },
                  }}
                >
                  Subir Cover
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(e) => {
                      dispatch(setCleanImage())
                      setCover(e.target.files[0]);

                    }}
                  />
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid rgba(255, 77, 73, 0.5)",
                    color: "rgba(76, 78, 100, 0.87)",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    "&:hover": { borderColor: "primary.main" },
                  }}
                  onClick={(e) => {
                    dispatch(setCleanImage())
                    setCover(null);
                  }}
                >
                  Limpiar
                </Button>
              </Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "rgba(76, 78, 100, 0.68)",
                }}
              >
                Formatos permitidos JPG, GIF o PNG. Tamaño maximo 800K
              </Typography>
            </Box>
          </Box>) : null}

      </Box>
      <Box sx={{ marginLeft: "10px" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.main",
            color: "secondary.dark",
            marginRight: "16px",
            "&:hover": { backgroundColor: "rgba(243, 236, 70, 0.95)" },
          }}
          onClick={(e) => {
            registerNotification();
          }}
        >
          Notificar
        </Button>
        <Button
          variant="outlined"
          sx={{
            color: "rgba(76, 78, 100, 0.87)",
            border: "1px solid rgba(109, 120, 141, 0.5)",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            "&:hover": { borderColor: "primary.main" },
          }}
          onClick={(e) => {
            dispatch(setClean());
          }}
        >
          Cancelar
        </Button>
      </Box>

    </Box>
  );
};

export default memo(Notifications);
