import React from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Divider,
  Button,
  MenuItem,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  getQuery, getDoc, setDoc,
  db,
  doc,
} from "../../infra/fifebase";
import {
  setWorkoutByUser,
  setDisabledIndex,
  setUpdateWorkoutItems,
  setGruposMusculares,
  setGuide,
  addStepInternal,
  delStepInternal,
  setUpdateStepItem
} from "../reducer/workout.reducer";
import Swal from "sweetalert2";


import { useSelector, useDispatch } from "react-redux";

const ViewWorkoutmodal = ({ uid }) => {
  React.useEffect(() => {
    workoutFindUser();
    findMuscle(null, null);
  }, []);

  const findGuidesForGroup = async (index, indexInternal, id) => {
    const guide = await getQuery("guide", null, [
      {
        item: "group",
        condition: "==",
        value: id,
      }

    ], {
      isStart: true,
      order: "id"
    });
    dispatch(
      setGuide({
        index,
        value: guide,
        indexInternal,
      })
    );
  };

  const findMuscle = async (action, where) => {
    const muscle = await getQuery("groupmuscle", action, where, {
      isStart: true,
      order: "id"
    });
    dispatch(setGruposMusculares(muscle));
  };

  const { workout, workoutByUser, itemDisabled, GrupoMuscular, guide } =
    useSelector((state) => state.workout);

  const workoutFindUser = async () => {
    dispatch(
      setWorkoutByUser({
        steps: [],
      })
    );
    var workouts = await getQuery("workout", null, [
      {
        item: "uid_user",
        condition: "==",
        value: uid,
      },
      {
        item: "active",
        condition: "==",
        value: true,
      },

    ], {
      isStart: true,
      order: "uid_user"
    });
    if (workouts.length > 0) {
      dispatch(setWorkoutByUser(workouts[0]))
      var workout = workouts[0];
      var newWorkout = {}
      for (const key in workout) {
        if (Object.hasOwnProperty.call(workout, key)) {
          Object.defineProperty(newWorkout, key, {
            value: workout[key],
            writable: true,
            enumerable: true,
            configurable: true
          });
        }
      }

      var steps = newWorkout.steps.map(async (step) => {
        var steps = step.steps.map(async (step) => {
          const {
            description,
            duration,
            group_muscle,
            guide,
            guide_name,
            repetitions,
            rest,
            series,
            workout_name
          } = step

          const guideNew = await getDoc(guide)
          return {
            description,
            duration,
            group_muscle,
            guide: guideNew.data(),
            guide_name,
            repetitions,
            rest,
            series,
            workout_name
          }
        })
        const Newsteps = await Promise.all(steps)
        const { day, description, name } = step
        return {
          day,
          description,
          name,
          steps: Newsteps
        }
      })
      const newSteps = await Promise.all(steps)
      newWorkout.steps = newSteps
      debugger
      dispatch(setWorkoutByUser(newWorkout));
    } else {
      dispatch(
        setWorkoutByUser({
          steps: [],
          isNull: true,
        })
      );
    }
  };

  const dispatch = useDispatch();



  const updateinfo = async () => {
    const workoutsSteps = workoutByUser
    const steps = workoutsSteps.steps.map((steps, index) => {
      const stepsInternal = steps.steps.map(step => {
        return {
          ...step,
          guide: doc(db, "guide", step.guide.id)
        }
      })
      return {
        ...steps,
        steps: stepsInternal
      }
    });
    const data = {
      ...workoutsSteps,
      steps
    }
   
    console.log(data)
    const idWorkout = doc(db, "workout", data.id)
    console.log(idWorkout)
    debugger;
   await setDoc(idWorkout, data);
    Swal.fire("hecho", "Rutina actualizada exitosamente", "success");
  }

  return workoutByUser ? (
    <Box>
      <Box sx={{ marginBottom: "12px" }}>
        <Typography variant="h5">Información Rutina</Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "15px",
            color: "rgba(76, 78, 100, 0.68)",
            marginBottom: "16px",
            letterSpacing: "0.4px",
          }}
        >
          Aquí puedes visualizar la informacion registrada de la rutina
        </Typography>
      </Box>

      <Box marginBottom={"12px"}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "15px",
            color: "rgba(76, 78, 100, 0.68)",
            marginY: "12px",
            letterSpacing: "0.4px",
          }}
        >
          Fechas:
        </Typography>

        <Typography>
          Valoración Inicial:{" "}
          {workoutByUser.date_start?.toDate
            ? workoutByUser.date_start.toDate().toISOString()
            : new Date().toISOString()}{" "}
        </Typography>
        <Typography>
          Próxima Valoración:{" "}
          {workoutByUser.date_start?.toDate
            ? workoutByUser.date_end.toDate().toISOString()
            : new Date().toISOString()}{" "}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 12,
          marginBottom: "12px",
        }}
      >
        <div>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "15px",
              color: "rgba(76, 78, 100, 0.68)",
              marginY: "12px",
              letterSpacing: "0.4px",
            }}
          >
            Datos Actuales
          </Typography>

          <Typography>Talla (m):{workoutByUser.height} </Typography>
          <Typography>Peso (kg): {workoutByUser.weight} </Typography>
          <Typography>Porcentaje de grasa: {workoutByUser.fat} </Typography>
          <Typography>
            Índice de masa corporal (IMC): {workoutByUser.imc}{" "}
          </Typography>
          <Typography>
            Índice Cintura-Cadera (ICC): {workoutByUser.icc}{" "}
          </Typography>
        </div>
      </Box>

      <Box>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "15px",
            color: "rgba(76, 78, 100, 0.68)",
            marginY: "12px",
            letterSpacing: "0.4px",
          }}
        >
          Recomendaciones
        </Typography>

        <Typography sx={{ marginY: "8px", letterSpacing: "0.4px" }}>
          {workoutByUser.recommendations}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "15px",
          color: "rgba(76, 78, 100, 0.68)",
          marginY: "12px",
          letterSpacing: "0.4px",
        }}
      >
        Rutina por día
      </Typography>

      {workoutByUser.steps.map((step, indexStep) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Día {step.day + 1}</Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Divider
              sx={{
                backgroundColor: "rgba(76, 78, 100, 0.68)",
                marginY: "16px",
              }}
            />
  {/* Descripcion dia  */}
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "30ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
              <TextField
                      label="Descripcion del dia"
                      id="outlined-basic"
                      variant="outlined"
                      value={step.description}
                      onChange={(e) => {
                        dispatch(setUpdateStepItem({
                          index: indexStep,
                          type:"description",
                          value : e.target.value
                        }))
                      }}
                    />
                     <TextField
                      label="Nombre del dia"
                      id="outlined-basic"
                      variant="outlined"
                      value={step.name}
                      onChange={(e) => {
                        dispatch(setUpdateStepItem({
                          index: indexStep,
                          type:"name",
                          value : e.target.value
                        }))
                      }}
                    />
              </div>
            </Box>
            <Divider
              sx={{
                backgroundColor: "rgba(76, 78, 100, 0.68)",
                marginY: "16px",
              }}
            />
            <Box>
              {step.steps.map((workout, indexInternal) => (
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "30ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>

                    <TextField
                      disabled={!itemDisabled[indexStep][indexInternal]}
                      label="Nombre Ejercicio"
                      id="outlined-basic"
                      variant="outlined"
                      value={workout.workout_name}
                      onChange={(e) => {
                        dispatch(
                          setUpdateWorkoutItems({
                            index: indexStep,
                            indexInternal,
                            type: "workout_name",
                            value: e.target.value,
                          })
                        );
                      }}
                    />

                    <TextField
                      disabled={!itemDisabled[indexStep][indexInternal]}
                      select
                      label="Grupo Muscular"
                      id="outlined-basic"
                      variant="outlined"
                      value={workout.group_muscle}
                      onChange={(e) => {
                        findGuidesForGroup(
                          indexStep,
                          indexInternal,
                          e.target.value
                        );
                        dispatch(
                          setUpdateWorkoutItems({
                            index: indexStep,
                            indexInternal,
                            type: "group_muscle",
                            value: e.target.value,
                          })
                        )

                      }}
                    >
                      {GrupoMuscular.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>

                  <Box>
                    <TextField
                      disabled={!itemDisabled[indexStep][indexInternal]}
                      label="Guía Asociada"
                      id="outlined-basic"
                      variant="outlined"
                      select
                      value={workout.guide?.id}
                      onChange={(e) => {
                        const guideId = guide[indexStep][indexInternal].find(guide => guide.id === e.target.value)
                        debugger;
                        dispatch(
                          setUpdateWorkoutItems({
                            index: indexStep,
                            indexInternal,
                            type: "guide",
                            value: e.target.value,
                            guideName: guideId.name
                          })
                        )

                      }}
                    >
                      {guide[indexStep].length !== 0 && guide[indexStep][indexInternal]
                        ? guide[indexStep][indexInternal].map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))
                        : null}
                    </TextField>
                    <TextField
                      disabled={!itemDisabled[indexStep][indexInternal]}
                      label="Duración del ejercicio"
                      id="outlined-basic"
                      variant="outlined"
                      value={workout.duration}
                      onChange={(e) => {
                        dispatch(
                          setUpdateWorkoutItems({
                            index: indexStep,
                            indexInternal,
                            type: "duration",
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                  </Box>

                  <Box>
                    <TextField
                      disabled={!itemDisabled[indexStep][indexInternal]}
                      label="descripción del ejercicio"
                      id="outlined-basic"
                      variant="outlined"
                      value={workout.description}
                      onChange={(e) => {
                        dispatch(
                          setUpdateWorkoutItems({
                            index: indexStep,
                            indexInternal,
                            type: "description",
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                    <TextField
                      disabled={!itemDisabled[indexStep][indexInternal]}
                      label="Series"
                      id="outlined-basic"
                      variant="outlined"
                      value={workout.series}
                      onChange={(e) => {
                        dispatch(
                          setUpdateWorkoutItems({
                            index: indexStep,
                            indexInternal,
                            type: "series",
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                  </Box>

                  <Box>
                    <TextField
                      disabled={!itemDisabled[indexStep][indexInternal]}
                      label="repeticiones"
                      id="outlined-basic"
                      variant="outlined"
                      value={workout.repetitions}
                      onChange={(e) => {
                        dispatch(
                          setUpdateWorkoutItems({
                            index: indexStep,
                            indexInternal,
                            type: "repetitions",
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                    <TextField
                      disabled={!itemDisabled[indexStep][indexInternal]}
                      label="Descanso"
                      id="outlined-basic"
                      variant="outlined"
                      value={workout.rest}
                      onChange={(e) => {
                        dispatch(
                          setUpdateWorkoutItems({
                            index: indexStep,
                            indexInternal,
                            type: "rest",
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "primary.main",
                        color: "secondary.dark",
                        m: 1,
                        "&:hover": {
                          backgroundColor: "rgba(243, 236, 70, 0.95)",
                        },
                      }}
                      onClick={(e) => {
                        findGuidesForGroup(
                          indexStep,
                          indexInternal,
                          workout.group_muscle
                        );

                        dispatch(
                          setDisabledIndex({
                            index: indexStep,
                            indexInternal,
                          })
                        );
                      }}
                    >
                      Habilitar
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "primary.main",
                        color: "secondary.dark",
                        m: 1,
                        "&:hover": {
                          backgroundColor: "#d33",
                        },
                      }}
                      onClick={(e) => {
                        dispatch(delStepInternal({
                          index: indexStep,
                          indexInternal
                        }))
                      }}
                    >
                      Eliminar
                    </Button>
                  </Box>

                  <Divider
                    sx={{
                      backgroundColor: "rgba(76, 78, 100, 0.68)",
                      marginY: "16px",
                    }}
                  />
                </Box>
              ))}

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  color: "secondary.dark",
                  m: 1,
                  "&:hover": {
                    backgroundColor: "rgba(243, 236, 70, 0.95)",
                  },
                }}
                onClick={(e) => {
                  dispatch(
                    addStepInternal({
                      index: indexStep,
                      group_muscle: GrupoMuscular[0].id,
                    })
                  );
                }}
              >
                Nuevo
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      <Button
        variant="contained"
        sx={{
          backgroundColor: "primary.main",
          color: "secondary.dark",
          m: 1,
          "&:hover": {
            backgroundColor: "#d33",
          },
        }}
        onClick={(e) => {
          updateinfo()
        }}
      >
        Actualizar
      </Button>
    </Box>
  ) : null;
};

export default ViewWorkoutmodal;
