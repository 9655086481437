import React, { memo, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import Toolbar from "@mui/material/Toolbar";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import Modal from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import Historymodal from "./modals/History.modal";
import ViewWorkoutmodal from "./modals/ViewWorkout.modal";
import Workoutmodal from "./modals/Workout.modal";

import {
  style,
  Search,
  SearchIconWrapper,
  StyledInputBase,
  StyledTableCell,
  StyledTableRow,
} from "./styles/ListCustomer.styles";
import {

  setUsers,
} from "./reducer/register.reducer";
import {

  getQuery,

  findLimit,
} from "../infra/fifebase";
import { useDispatch, useSelector } from "react-redux";

const ListCustomer = () => {
  const [uid, setUid] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [option, setOption] = React.useState("");
  const [start, setStart] = React.useState(null);
  const [end, setEnd] = React.useState(null);
  const [ documento, setDocument] = React.useState("");

  const handleOpen = (option) => {
    setOpen(true);
    setOption(option);
  };

  const SwitchModal = () => {
    switch (option) {
      case "create":
        return <Workoutmodal uid={uid} />;
      case "show":
        return <ViewWorkoutmodal uid={uid} />;
      case "history":
        return <Historymodal uid={uid} />;
      default:
        return null;
    }
  };

  const handleClose = () => setOpen(false);

  const { user, users, update } = useSelector((state) => state.register);
  const dispatch = useDispatch();

  const findCustomer = async (action, where, isStart) => {
    debugger;
    where  = [
      // {
      //   item:"state",
      //   condition:"!=",
      //   value:"suspendido"
      // }
    ]

    if(documento!==""){
      where.push({
        item:"state",
        condition:"!=",
        value:"suspendido"
      })
      where.push({
        item:"id_card",
        condition:"==",
        value:documento
      })

    }
    const customer = await getQuery("users", action, where, {
      isStart,
      order: "uid",
      state:true
    }, 5);
    const { limitStart, limitEnd } = findLimit(customer);
    setStart(limitStart);
    setEnd(limitEnd);
    dispatch(setUsers(customer));
  };

  React.useEffect(() => {
    findCustomer(start, null, true);
  }, []);

  const hideDigits = (numbers) => {
    const visibleNumbers = 3;
    const longitudTotal = numbers?.length ?? 0;
    if (longitudTotal != 0) {
      const maskedNumbers = longitudTotal - visibleNumbers;
      const masked = "*".repeat(maskedNumbers) + numbers.slice(-visibleNumbers);
      return masked;
    }
    return "";
  };
  const hideMail = (mail) => {
    const splitMail = mail.split("@");
    const nameUser = splitMail[0];
    const domain = splitMail[1];

    const firstVisibleCharacters = 3; // Número de caracteres visibles al principio del nombre de usuario
    const hiddenCharacters = nameUser.length - firstVisibleCharacters;
    const hiddenUserName =
      nameUser.slice(0, firstVisibleCharacters) + "*".repeat(hiddenCharacters);

    return hiddenUserName + "@" + domain;
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ marginBottom: "12px" }}>
          <Typography variant="h5">Listado Clientes</Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "15px",
              color: "rgba(76, 78, 100, 0.68)",
              marginBottom: "12px",
              letterSpacing: "0.4px",
            }}
          >
            Administra la información de tus clientes
          </Typography>
        </Box>

        <Toolbar>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Buscar…"
              inputProps={{ "aria-label": "search" }}
              onKeyUp={(e) => {
                // Obtener el código de tecla
                const keyCode = e.keyCode;
                if (keyCode === 13 || keyCode === 9) {
                  findCustomer(null,null,true)
                }

              }}
              onChange={(e)=>{
                setDocument(e.target.value)
              }}
            />
          </Search>
        </Toolbar>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ backgroundColor: "primary.main" }}>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: 700 }}>
                Nombres
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: 700 }}>
                Apellidos
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: 700 }}>
                Cédula
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: 700 }}>
                Correo Eléctronico
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: 700 }}>
                Estado
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ fontWeight: 700 }}>
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {user.first_name}
                </StyledTableCell>
                <StyledTableCell align="left">{user.last_name}</StyledTableCell>
                <StyledTableCell align="left">
                  {hideDigits(user.id_card)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {hideMail(user.email)}
                </StyledTableCell>
                <StyledTableCell align="left">{user.state}</StyledTableCell>
                <StyledTableCell align="center">
                  <Box>
                    <IconButton
                      aria-label="routine"
                      onClick={() => {
                        setUid(user.uid);
                        handleOpen("create");
                      }}
                    >
                      <LibraryAddOutlinedIcon />
                    </IconButton>
                    <IconButton
                      aria-label="routine"
                      onClick={() => {
                        setUid(user.uid);
                        handleOpen("show");
                      }}
                    >
                      <LibraryAddCheckOutlinedIcon />
                    </IconButton>
                    <IconButton
                      aria-label="routine"
                      onClick={() => {
                        setUid(user.uid);
                        handleOpen("history");
                      }}
                    >
                      <HistoryOutlinedIcon />
                    </IconButton>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          aria-label="before"
          onClick={() => {
            findCustomer(start, null, false);
          }}
        >
          <NavigateBeforeOutlinedIcon fontSize="large" />
        </IconButton>
        <IconButton
          aria-label="next"
          onClick={() => {
            findCustomer(end, null, true);
          }}
        >
          <NavigateNextOutlinedIcon fontSize="large" />
        </IconButton>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
            }}
          >
            <IconButton
              aria-label="delete"
              onClick={handleClose}
              size="large"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <SwitchModal />
        </Box>
      </Modal>
    </Box>
  );
};

export default memo(ListCustomer);
