import { createSlice } from "@reduxjs/toolkit";

const createItemsWorkout = () => {
  let workoutItemsDay = [];
  for (let index = 0; index < 7; index++) {
    workoutItemsDay.push({
      workout_name: "",
      group_muscle: "",
      guide: "",
      description: "",
      series: "",
      repetitions: "",
      rest: "",
      duration: "",
      guides: [],
      steps: [],
    });
  }
  return workoutItemsDay;
};

export const WorkoutSlice = createSlice({
  name: "workout",
  initialState: {
    workout: {
      index: 0,
      date_start: "",
      date_end: "",
      height: "",
      weight: "",
      fat: "",
      imc: "",
      icc: "",
      recommendations: "",
      workoutItems: createItemsWorkout(),
    },
    workoutByUser:{
      steps:[],
      isNull:true
    },
    GrupoMuscular: [],
    update: false,
    itemDisabled: [[]],
    guide: [[]],
    descriptionDay:{},
    nameDay:{}
  },
  reducers: {
    setNameDay:(state,action)=>{
      state.nameDay[action.payload.index] = action.payload.value
    },
    setDescriptionDay:(state,action)=>{
      state.descriptionDay[action.payload.index] = action.payload.value
    },
    setIndex: (state, action) => {
      state.workout.index = action.payload;
    },
    setWorkout: (state, action) => {
      state.workout[action.payload.type] = action.payload.value;
    },

    setWorkoutItem: (state, action) => {
      if(action.payload.type === "guide"){
        state.workout.workoutItems[action.payload.index]["guide"] = action.payload.guide
        state.workout.workoutItems[action.payload.index]["guide_name"] = action.payload.guide_name;
      }else{
        state.workout.workoutItems[action.payload.index][action.payload.type] =
          action.payload.value;
      }
    },
    setWorkoutItemStep: (state, action) => {
      state.workout.workoutItems[action.payload.index][
        action.payload.type
      ].push(action.payload.value);
    },
    setClean: (state, action) => {
      state.workout = {
        index: 0,
        date_start: "",
        date_end: "",
        height: "",
        weight: "",
        fat: "",
        imc: "",
        icc: "",
        recommendations: "",
        workoutItems: createItemsWorkout(),
      };
    },
    setCleanItemWorkout: (state, action) => {
      state.workout.workoutItems[action.payload.index] = {
        workout_name: "",
        group_muscle: "",
        guide: "",
        description: "",
        series: "",
        repetitions: "",
        rest: "",
        duration: "",
        guides: [],
        steps: [],
      };
    },
    setWorkoutComplete: (state, action) => {
      state.workout = action.payload;
    },
    setWorkoutByUser: (state, action) => {
      const workoutByUser=  action.payload;
      const itemDisabled = [];
      const guide = [];
      if (!workoutByUser.isNull) {
        for (let i = 0; i < action.payload.steps.length; i++) {
          itemDisabled.push([]);
          guide.push([]);
          for (let j = 0; j < action.payload.steps[i]; j++) {
            itemDisabled[i].push(false);
            guide[i].push([]);
          }
        }
        state.guide   = guide
        state.workoutByUser = workoutByUser
        
        state.itemDisabled = itemDisabled     
      }
    },
    setDisabledIndex: (state, action) => {
      state.itemDisabled[action.payload.index][action.payload.indexInternal] = !state.itemDisabled[action.payload.index][action.payload.indexInternal] 
    },
    setUpdate: (state, action) => {
      state.update = action.payload;
    },
    setGruposMusculares: (state, action) => {
      state.GrupoMuscular = action.payload;
    },
    setUpdateStepItem :(state, action)=>{
      state.workoutByUser.steps[action.payload.index][action.payload.type] =  action.payload.value;
    },
    setUpdateWorkoutItems: (state, action) => {
      if(action.payload.type === "guide"){
        state.workoutByUser.steps[action.payload.index].steps[
          action.payload.indexInternal
        ][action.payload.type].id = action.payload.value;
   
        state.workoutByUser.steps[action.payload.index].steps[
          action.payload.indexInternal
        ]["guide_name"] = action.payload.guideName
      }else{
        state.workoutByUser.steps[action.payload.index].steps[
          action.payload.indexInternal
        ][action.payload.type] = action.payload.value;
      }
      
    },
    setGuide: (state, action) => {
      state.guide[action.payload.index][action.payload.indexInternal] = action.payload.value;
    },
    addStepInternal:(state,action)=>{
      const workoutByUser=state.workoutByUser
      workoutByUser.steps[action.payload.index].steps.push({
        description:"",
        group_muscle:action.payload.group_muscle,
        guide:{
          id:""
        },
        repetitions:"",
        rest:"",
        series:"",
        workout_name:""
      })
      state.workoutByUser=workoutByUser
      state.itemDisabled[action.payload.index].push(false)
      state.guide[action.payload.index].push([])
    },
    delStepInternal :(state,action)=>{
      const workoutByUser=state.workoutByUser
      workoutByUser.steps[action.payload.index].steps.splice(action.payload.indexInternal,1)
      state.workoutByUser=workoutByUser
      state.itemDisabled[action.payload.index].splice(action.payload.indexInternal,1)
      state.guide[action.payload.index].splice(action.payload.indexInternal,1)
    }
  },
});

export const {
  setWorkout,
  setClean,
  setWorkoutComplete,
  setWorkouts,
  setUpdate,
  setGruposMusculares,
  setWorkoutItem,
  setIndex,
  setWorkoutItemStep,
  setCleanItemWorkout,
  setDisabledIndex,
  setWorkoutByUser,
  setUpdateWorkoutItems,
  setGuide,
  addStepInternal,
  delStepInternal,
  setDescriptionDay,
  setNameDay,
  setUpdateStepItem
} = WorkoutSlice.actions;

export default WorkoutSlice.reducer;
