import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setClean,
  setMachine,
  setMachineComplete,
  setMachines,
  setUpdate,
  setGrupoMuscular,
  setCleanImage,
} from "./reducer/machine.reducer";
import {
  auth,
  storage,
  uploadBytes,
  setDoc,
  db,
  doc,
  getQuery,
  getDownloadURL,
  updateDoc,
  deleteDoc,
} from "../infra/fifebase";
import { v1 as uuidv1 } from "uuid";

import {
  Box,
  Typography,
  Toolbar,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";

import {
  CustomWidthTooltip,
  Search,
  SearchIconWrapper,
  StyledInputBase,
  StyledTableCell,
  StyledTableRow,
} from "./styles/WorkoutMachine.styles";
import imgUser from "../assets/Avatar.png";

import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";

const WorkoutMachine = () => {
  const [file, setFile] = React.useState(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
    findMuscles(null, null);
    findMachine(null, null);
  }, []);

  const findGroupMuscle = (id) => {
    const muscle = GrupoMuscular.find((muscle) => muscle.id === id);

    return muscle?.name;
  };

  const findMuscles = async (action, where) => {
    const muscle = await getQuery("groupmuscle", action, where, {
      isStart: true, 
      order: "name"
    });
    dispatch(setGrupoMuscular(muscle));
  };
  const findMachine = async (action, where) => {
    const machine = await getQuery("machine", action, where, {
      isStart: true,
      order: "group"
    });
    dispatch(setMachines(machine));
  };

  const registerMachine = async () => {
    try {
      if (!update) {
        const id = uuidv1();
        const snapshot = await uploadBytes(
          storage("machine/mc-" + id + ".png"),
          file
        );

        const url_image = await getDownloadURL(
          storage(snapshot.metadata.fullPath)
        );

        await setDoc(doc(db, "machine", id), {
          id,
          name: machine.name,
          description: machine.description,
          group: machine.group,
          url_image,
        });
        Swal.fire("hecho", "maquina registrada exitosamente", "success");

        setFile(null);
        setUpdate(false);
        dispatch(setClean());
        findMachine(null, null);
        return;
      }

      let url_image = "";
      if (file) {
        const id = uuidv1();
        const snapshotImage = await uploadBytes(
          storage("machine/mc-" + id + ".png"),
          file
        );
        url_image = await getDownloadURL(
          storage(snapshotImage.metadata.fullPath)
        );
      }

      updateDoc(doc(db, "machine", machine.id), {
        id: machine.id,
        name: machine.name,
        description: machine.description,
        group: machine.group,
        url_image: file ? url_image : machine.url_image,
      });
      Swal.fire("hecho", "maquina actualizada exitosamente", "success");
      setFile(null);
      setUpdate(false);
      dispatch(setClean());
      findMachine(null, null);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.message,
        footer: "",
      });
    }
  };

  const { machine, machines, update, GrupoMuscular } = useSelector(
    (state) => state.machine
  );

  const handlerUpdate = async (machine) => {
    dispatch(setUpdate(true));
    const url_image = await getDownloadURL(storage(machine.url_image));
    console.log(machine);
    dispatch(
      setMachineComplete({
        ...machine,
        url_image,
      })
    );
  };

  const deleteMachine = async (id) => {
    try {
      Swal.fire({
        title: "Estas seguro",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const guides = await getQuery("guide", null, [
            {
              item: "machine",
              condition: "==",
              value: id,
            }
          ],
          
          {
            isStart: true, 
            order: "name"
          });

          if (guides.length !== 0) {
            Swal.fire(
              "Lo sentimos",
              "No puedes eliminar esta maquina porque tiene guias asociadas",
              "question"
            );
            return;
          }

          await deleteDoc(doc(db, "machine", id));
          findMachine(null, null);
          Swal.fire("Eliminado!", "La Maquina ha sido eliminada", "success");
        }
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.message,
        footer: "",
      });
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ marginBottom: "12px" }}>
          <Typography variant="h5">Máquinas de Ejercicio</Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "15px",
              color: "rgba(76, 78, 100, 0.68)",
              marginBottom: "12px",
              letterSpacing: "0.4px",
            }}
          >
            Administra la información de las máquinas disponibles
          </Typography>
        </Box>
        {/* <Toolbar>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Buscar…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Toolbar> */}
      </Box>

      <Typography variant="h6" marginBottom={"12px"}>
        Crea una nueva Máquina
      </Typography>

      <Box
        marginBottom={"12px"}
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "45ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Rigistra aquí la información de la Máquina</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "flex", gap: 1, marginBottom: "24px" }}>
              <Box sx={{ width: 120, height: 120, marginRight: "20px" }}>
                <img
                  width={120}
                  height={120}
                  src={
                    !file
                      ? machine.url_image === ""
                        ? imgUser
                        : machine.url_image
                      : URL.createObjectURL(file)
                  }
                  alt="user"
                />
              </Box>

              <Box
                sx={{
                  marginBottom: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Box>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "secondary.dark",
                      marginRight: "16px",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      "&:hover": {
                        backgroundColor: "rgba(243, 236, 70, 0.95)",
                      },
                    }}
                  >
                    Subir Foto
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      border: "1px solid rgba(255, 77, 73, 0.5)",
                      color: "rgba(76, 78, 100, 0.87)",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      "&:hover": { borderColor: "primary.main" },
                    }}
                    onClick={(e) => {
                      dispatch(setCleanImage());
                      setFile(null);
                    }}
                  >
                    Limpiar
                  </Button>
                </Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "rgba(76, 78, 100, 0.68)",
                  }}
                >
                  Formatos permitidos JPG, GIF or PNG.
                </Typography>
              </Box>
            </Box>

            <div>
              <TextField
                id="outlined-basic"
                label="Nombre Máquina"
                variant="outlined"
                onChange={(e) => {
                  dispatch(
                    setMachine({
                      type: "name",
                      value: e.target.value,
                    })
                  );
                }}
                value={machine.name}
              />
              <TextField
                id="outlined-select-currency"
                select
                label="Grupo Muscular"
                defaultValue="Cliente"
                helperText="Por favor seleccione un grupo muscular"
                onChange={(e) => {
                  dispatch(
                    setMachine({
                      type: "group",
                      value: e.target.value,
                    })
                  );
                }}
                value={machine.group}
              >
                {GrupoMuscular.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "15px",
                  color: "rgba(76, 78, 100, 0.68)",
                  marginBottom: "10px",
                  letterSpacing: "0.4px",
                  marginLeft: "10px",
                }}
              >
                Descripción
              </Typography>

              <TextField
                variant="outlined"
                placeholder="Ingresa aquí tu descripción..."
                multiline
                rows={5}
                sx={{
                  width: "91ch",
                  marginLeft: "10px",
                  borderRadius: "6px",
                }}
                name="Outlined"
                onChange={(e) => {
                  dispatch(
                    setMachine({
                      type: "description",
                      value: e.target.value,
                    })
                  );
                }}
                value={machine.description}
              />
            </div>

            <Box sx={{ m: 1 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  color: "secondary.dark",
                  marginRight: "16px",
                  "&:hover": { backgroundColor: "rgba(243, 236, 70, 0.95)" },
                }}
                onClick={(e) => {
                  registerMachine();
                }}
              >
                {update ? "Actualizar" : "Registrar"}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "rgba(76, 78, 100, 0.87)",
                  border: "1px solid rgba(109, 120, 141, 0.5)",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "24px",
                  "&:hover": { borderColor: "primary.main" },
                }}
                onClick={(e) => {
                  dispatch(setClean());
                }}
              >
                Cancelar
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead sx={{ backgroundColor: "primary.main" }}>
              <TableRow>
                <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                  Nombre Maquina
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                  Grupo Muscular
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                  Descripción
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ fontWeight: 700 }}>
                  Acciones
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {machines.map((machine) => (
                <StyledTableRow>
                  <StyledTableCell align="right">
                    {machine.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {findGroupMuscle(machine.group)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <CustomWidthTooltip title={machine.description}>
                      <Button
                        sx={{
                          color: "secondary.dark",
                          textTransform: "capitalize",
                        }}
                      >
                        {machine.description.slice(0, 50)}
                      </Button>
                    </CustomWidthTooltip>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Box>
                      <IconButton
                        aria-label="edit"
                        onClick={(e) => {
                          handlerUpdate(machine);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={(e) => {
                          deleteMachine(machine.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton 
            aria-label="before"
            // onClick={() => {
            //   findMachine(start, null, true);
            // }}
          >
            <NavigateBeforeOutlinedIcon fontSize="large" />
          </IconButton>
          <IconButton 
            aria-label="next"
            // onClick={() => {
            //   findMachine(end, null, true);
            // }}
          >
            <NavigateNextOutlinedIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(WorkoutMachine);
