import { configureStore } from "@reduxjs/toolkit"; 
import loginReducer from "./domain/auth/reducer/auth.reducer"
import registerReducer from "./components/reducer/register.reducer";
import machineReducer  from "./components/reducer/machine.reducer";
import notificationReducer from  "./components/reducer/notification.reducer"
import guidesReducer from "./components/reducer/guides.reducer";
import calendarReducer from "./components/reducer/calendar.reducer";
import workoutReducer from "./components/reducer/workout.reducer";
import historyReducer from "./components/reducer/history.reducer";

const store = configureStore({
  reducer: {
    login: loginReducer,
    register: registerReducer,
    machine : machineReducer,
    notification : notificationReducer,
    guide : guidesReducer,
    calendar : calendarReducer,
    workout: workoutReducer,
    history: historyReducer,
  }
})

export default store