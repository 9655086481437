import React from "react";
import {
  Typography,
  Box,
  TextField,
  MenuItem,
  Button,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { v1 as uuidv1 } from "uuid";
import {
  setHistory,
  setClean,
  setHistories,
  setHistoryWeights,
  setHistoryFats,
  setHistoryImcs,
  setHistoryIccs,
} from "../reducer/history.reducer";
import {
  setDoc,
  db,
  doc,
  getQuery,
} from "../../infra/fifebase";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";

const Historymodal = ({ uid }) => {
  const Meses = [
    {
      value: "1",
      label: "Enero",
    },
    {
      value: "2",
      label: "Febrero",
    },
    {
      value: "3",
      label: "Marzo",
    },
    {
      value: "4",
      label: "Abril",
    },
    {
      value: "5",
      label: "Mayo",
    },
    {
      value: "6",
      label: "Junio",
    },
    {
      value: "7",
      label: "Julio",
    },
    {
      value: "8",
      label: "Agosto",
    },
    {
      value: "9",
      label: "Septiembre",
    },
    {
      value: "10",
      label: "Octubre",
    },
    {
      value: "11",
      label: "Noviembre",
    },
    {
      value: "12",
      label: "Diciembre",
    },
  ];

  const {
    history,
    histories,
    filters,
    historyWeights,
    historyFats,
    historyImcs,
    historyIccs,
  } = useSelector((state) => state.history);
  const dispatch = useDispatch();

  const registerHistory = async () => {
    try {
      const id = uuidv1();
      await setDoc(doc(db, "history", id), {
        id,
        year: parseInt(history.year),
        month: history.month,
        weight: parseInt(history.weight),
        fat: parseInt(history.fat),
        imc: parseInt(history.imc),
        icc: parseInt(history.icc),
        user_id: uid,
      });
      Swal.fire("hecho", "Historial registrado exitosamente", "success");
      dispatch(setClean());
      return;
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Ooops...",
        text: e.message,
        footer: "",
      });
    }
  };

  React.useEffect(() => {
    findHistory(null, null);
  }, []);

  const findHistory = async (action, where) => {
    findNamePlot(action);
  };

  const findNamePlot = async (action) => {
    const historyWeights = await getQuery("history", action, [
      {
        item: "user_id",
        condition: "==",
        value: uid,
      },
    ],
    {isStart:true,
  order:"year"});
    const { kgs, fats, imc, icc } = historyWeights.reduce(
      (accumulator, currentValue) => {
        accumulator.kgs.push({
          month: currentValue.month,
          value: currentValue.weight,
          year: currentValue.year,
        });

        accumulator.fats.push({
          month: currentValue.month,
          value: currentValue.fat,
          year: currentValue.year,
        });

        accumulator.imc.push({
          month: currentValue.month,
          value: currentValue.imc,
          year: currentValue.year,
        });

        accumulator.icc.push({
          month: currentValue.month,
          value: currentValue.icc,
          year: currentValue.year,
        });

        return accumulator;
      },
      {
        kgs: [],
        fats: [],
        imc: [],
        icc: [],
      }
    );

    dispatch(setHistoryWeights(kgs));
    dispatch(setHistoryFats(fats));
    dispatch(setHistoryImcs(imc));
    dispatch(setHistoryIccs(icc));
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ marginBottom: "12px" }}>
        <Typography variant="h5">Historial</Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "15px",
            color: "rgba(76, 78, 100, 0.68)",
            marginBottom: "16px",
            letterSpacing: "0.4px",
          }}
        >
          Aquí puedes relacionar la Información de cada historial
        </Typography>

        <Box
          sx={{
            "& .MuiTextField-root": { m: 1, width: "30ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              id="outlined-basic"
              label="año"
              variant="outlined"
              onChange={(e) => {
                dispatch(
                  setHistory({
                    type: "year",
                    value: e.target.value,
                  })
                );
              }}
              value={history.name}
            />
            <TextField
              id="outlined-select-currency"
              select
              label="Meses del año"
              helperText="Selecciona un mes"
              onChange={(e) => {
                dispatch(
                  setHistory({
                    type: "month",
                    value: e.target.value,
                  })
                );
              }}
              value={history.month}
            >
              {Meses.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div>
            <TextField
              id="outlined-basic"
              label="Peso (kg)"
              variant="outlined"
              onChange={(e) => {
                dispatch(
                  setHistory({
                    type: "weight",
                    value: e.target.value,
                  })
                );
              }}
              value={history.weight}
            />
            <TextField
              id="outlined-basic"
              label="% de Grasa"
              variant="outlined"
              onChange={(e) => {
                dispatch(
                  setHistory({
                    type: "fat",
                    value: e.target.value,
                  })
                );
              }}
              value={history.fat}
            />
          </div>

          <div>
            <TextField
              id="outlined-basic"
              label="IMC"
              variant="outlined"
              onChange={(e) => {
                dispatch(
                  setHistory({
                    type: "imc",
                    value: e.target.value,
                  })
                );
              }}
              value={history.imc}
            />
            <TextField
              id="outlined-basic"
              label="ICC"
              variant="outlined"
              onChange={(e) => {
                dispatch(
                  setHistory({
                    type: "icc",
                    value: e.target.value,
                  })
                );
              }}
              value={history.icc}
            />
          </div>
        </Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.main",
            color: "secondary.dark",
            m: 1,
            "&:hover": { backgroundColor: "rgba(243, 236, 70, 0.95)" },
          }}
          onClick={(e) => {
            registerHistory();
          }}
        >
          Agregar
        </Button>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Peso(kg)" {...a11yProps(0)} />
            <Tab label="% Grasa" {...a11yProps(1)} />
            <Tab label="IMC" {...a11yProps(2)} />
            <Tab label="ICC" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "primary.main" }}>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: 700 }}>
                    Mes
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    Peso(Kg)
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    AÑo
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyWeights.map((history) => (
                  <TableRow>
                    <TableCell component="th" scope="row" align="left">
                      {Meses.map((mes) =>
                        mes.value === history.month ? mes.label : null
                      )}
                    </TableCell>
                    <TableCell align="center">{history.value}</TableCell>
                    <TableCell align="center">{history.year}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "primary.main" }}>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: 700 }}>
                    Mes
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    % de Grasa
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    AÑo
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyFats.map((history) => (
                  <TableRow>
                    <TableCell component="th" scope="row" align="left">
                      {Meses.map((mes) =>
                        mes.value === history.month ? mes.label : null
                      )}
                    </TableCell>
                    <TableCell align="center">{history.value}</TableCell>
                    <TableCell align="center">{history.year}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "primary.main" }}>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: 700 }}>
                    Mes
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    IMC
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    AÑo
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyImcs.map((history) => (
                  <TableRow>
                    <TableCell component="th" scope="row" align="left">
                      {Meses.map((mes) =>
                        mes.value === history.month ? mes.label : null
                      )}
                    </TableCell>
                    <TableCell align="center">{history.value}</TableCell>
                    <TableCell align="center">{history.year}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "primary.main" }}>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: 700 }}>
                    Mes
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    ICC
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    AÑo
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyIccs.map((history) => (
                  <TableRow>
                    <TableCell component="th" scope="row" align="left">
                      {Meses.map((mes) =>
                        mes.value === history.month ? mes.label : null
                      )}
                    </TableCell>
                    <TableCell align="center">{history.value}</TableCell>
                    <TableCell align="center">{history.year}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Historymodal;
