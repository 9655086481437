import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: '#F3EC46'
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#211D1C',
      light: '#4c4e6414',
      contrastText: 'rgba(76, 78, 100, 0.87)'
    }
  }
})

export default theme