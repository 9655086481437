import { Routes, Route } from "react-router-dom";
import Home from "./domain/home";
import Auth from "./domain/auth";
import ResetPassword from "./domain/resetPassword";
import "./app.css";
import { useSelector, useDispatch } from "react-redux";

function App() {

  const { auth } = useSelector((state) => state.login);

  return auth ? (
    <Routes>
      <Route path="/*" element={<Home />}></Route>
      <Route path="/resetpassword" element={<ResetPassword />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/*" element={<Auth />}></Route>
    </Routes>
  )


  // </Provider>
}

export default App;
