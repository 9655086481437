import React, { memo } from "react";
import { Box, Typography, MenuItem } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Toolbar from "@mui/material/Toolbar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  setClean,
  setMachines,
  setGuide,
  setGuidesEasy,
  setGuidesMedium,
  setGuidesHard,
  setUpdate,
  setGruposMusculares,
  setCleanImage,
  setCleanVideo
} from "./reducer/guides.reducer";
import { v1 as uuidv1 } from "uuid";
import {
  auth,
  storage,
  uploadBytes,
  setDoc,
  db,
  doc,
  getQuery,
  getDownloadURL,
  updateDoc,
  deleteDoc,
} from "../infra/fifebase";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import imgUser from "../assets/Avatar.png";
import {
  CustomWidthTooltip,
  Search,
  SearchIconWrapper,
  StyledInputBase,
  StyledTableCell,
  StyledTableRow,
} from "./styles/guide.styles";
import Swal from "sweetalert2";
import { setGuideComplete } from "./reducer/guides.reducer";

const Guides = () => {
  const [value, setValue] = React.useState(0);

  const { machines, update, guide, guidesEasy, guidesHard , guidesMedium,  GrupoMuscular } = useSelector(
    (state) => state.guide
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const registerGuides = async () => {
    try {
      if (!update) {
        const id = uuidv1();
        const snapshot = await uploadBytes(
          storage("guide/guide-" + id + ".mp4"),
          file
        );
        const snapshotCover = await uploadBytes(
          storage("guide/cover/guide-" + id + ".png"),
          cover
        );

    const url_video = await getDownloadURL(storage( snapshot.metadata.fullPath))
    const url_cover = await getDownloadURL( storage(snapshotCover.metadata.fullPath))
  

        await setDoc(doc(db, "guide", id), {
          id,
          name: guide.name,
          description: guide.description,
          group: guide.group,
          url_video,
          url_cover,
          machine: doc(db, "machine", guide.machine),
          level: guide.level,
        });
        Swal.fire("hecho", "guias registrada exitosamente", "success");
        setFile(null);
        setCover(null)
        setUpdate(false);
        dispatch(setClean());
        findGuide(null, null);
        return;
      }

      let url_cover = ""
      let url_video = ""
      if(cover){
        const id = uuidv1();
        const snapshotCover = await uploadBytes(
          storage("guide/cover/guide-" + id + ".png"),
          cover
        );
        url_cover= await getDownloadURL(storage( snapshotCover.metadata.fullPath))  
      }

      if(file){
        const id = uuidv1();

        const snapshot = await uploadBytes(
          storage("guide/guide-" + id + ".mp4"),
          file
        );
       url_video =await getDownloadURL(storage( snapshot.metadata.fullPath))
      }




      await updateDoc(doc(db, "guide", guide.id), {
        id: guide.id,
        name: guide.name,
        description: guide.description,
        group: guide.group,
        url_video: (file) ? url_video : guide.url_video,
        url_cover: (cover) ? url_cover : guide.url_cover,
        machine:doc(db, "machine", guide.machine),
        level: guide.level,
      });

      findGuide(null,null)
      Swal.fire("hecho", "guias actualizada exitosamente", "success");
      setFile(null);
      setCover(null)
      setUpdate(false);
      dispatch(setClean());
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.message,
        footer: "",
      });
    }
  };

  const handlerUpdate = async (guide) => {
    dispatch(setUpdate(true))
    console.log(guide);
    setFile(null)
    setCover(null)
    dispatch(
      setGuideComplete({...guide,
      machine:guide.machine.id})
    )
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [file, setFile] = React.useState(null);

  const [cover, setCover] = React.useState(null);

  const longText = `
  Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
  Praesent non nunc mollis, fermentum neque at, semper arcu.
  Nullam eget est sed sem iaculis gravida eget vitae justo.
  `;



  const Levels = [
    {
      value: "1",
      label: "Principiante",
    },
    {
      value: "2",
      label: "Intermedio",
    },
    {
      value: "3",
      label: "Avanzado",
    },
  ];

  const dispatch = useDispatch();

  React.useEffect(() => {
    findMachine(null, null);
  }, []);

  const findGuide = async (action, where) => {
    findGuideLevel(action)
  };



  const findGuideLevel = async (action) => {
    const guide = await getQuery("guide", action, null, {
      isStart: true,
      order: "id"
    },1000)


    const guideEasy= guide.filter((item)=>item.level === "1")
    const guideMedium= guide.filter((item)=>item.level === "2")
    const guideHard= guide.filter((item)=>item.level === "3")


    dispatch(setGuidesEasy(guideEasy))
    dispatch(setGuidesMedium(guideMedium))
    dispatch(setGuidesHard(guideHard))


  }


  const findMachine = async (action, where) => {
    const machine = await getQuery("machine", action, where,{
      isStart: true,
      order: "name",
    });
    dispatch(setMachines(machine));
    findGuide(null, null);
    findMuscle(null, null)
  };

  const findGroupMuscle = (id) => {
    const muscle = GrupoMuscular.find((muscle) => muscle.id === id);
    return muscle?.name;
  };

  const findMuscle = async (action, where) => {
    if (GrupoMuscular.length === 0){
      const muscles = await getQuery("groupmuscle", action, where,{
        isStart: true,
        order: "name"
      });
      dispatch(setGruposMusculares(muscles))
    }

  }

  const findLevel = (id) => {
    const level = Levels.find((level) => level.value === id);
    return level.label;
  };

  const findMachineById = (id) => {
    const machine = machines.find((machine) => machine.id === id);
    return machine?.name;
  };


  function ShowVideo(){
    if(file){
      return(
      <video width={300} height={300} controls>
        <source src={ URL.createObjectURL(file) } type="video/mp4" />
        Tu navegador no soporta el elemento <code>video</code>.
      </video>
      )
    }

    if(guide.url_video !== ""){
      return(
        <video width={300} height={300} controls>
        <source src={file ? URL.createObjectURL(file) : guide.url_video } type="video/mp4" />
        Tu navegador no soporta el elemento <code>video</code>.
      </video>
      )
    } 
    return (
      <img src={imgUser} alt="user" />
    )
    
  }

  const deleteGuide = async (id) => {
    try {
      Swal.fire({
        title: "Estas seguro",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const guides = await getQuery("guide", null, [
            {
              item: "guide",
              condition: "==",
              value: id,
            },
            
          ],{
            isStart:true,
            order:"id"
          });

          if (guides.length !== 0) {
            Swal.fire(
              "Lo sentimos",
              "No puedes eliminar esta maquina porque tiene guias asociadas",
              "question"
            );
            return;
          }

          await deleteDoc(doc(db, "guide", id));
          findGuide(null,null)
          Swal.fire("Eliminado!", "La Guia ha sido eliminada", "success");
        }
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.message,
        footer: "",
      });
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ marginBottom: "12px" }}>
          <Typography variant="h5">Listado Guías</Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "15px",
              color: "rgba(76, 78, 100, 0.68)",
              marginBottom: "12px",
              letterSpacing: "0.4px",
            }}
          >
            Administra la información de todas las Guías
          </Typography>
        </Box>

        {/* <Toolbar>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Buscar…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Toolbar> */}
      </Box>

      <Typography variant="h6" marginBottom={"12px"}>
        Crea una nueva Guía
      </Typography>

      <Box
        marginBottom={"12px"}
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "45ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Rigistra aquí la información de la Guía</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "flex", gap: 1, marginBottom: "24px" }}>
              <Box sx={{ width: 120, height: 120, marginRight: "20px" }}>
                <img
                  width={120}
                  height={120}
                  src={!cover ? (guide.url_cover === "" ? imgUser : guide.url_cover) : URL.createObjectURL(cover)}
                  alt="user"
                />
              </Box>

              <Box
                sx={{
                  marginBottom: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Box>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "secondary.dark",
                      marginRight: "16px",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      "&:hover": {
                        backgroundColor: "rgba(243, 236, 70, 0.95)",
                      },
                    }}
                  >
                    Subir Cover
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        dispatch(setCleanImage())
                        setCover(e.target.files[0]);
                      }}
                    />
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      border: "1px solid rgba(255, 77, 73, 0.5)",
                      color: "rgba(76, 78, 100, 0.87)",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      "&:hover": { borderColor: "primary.main" },
                    }}
                    onClick={(e) => {
                      dispatch(setCleanImage())
                      setFile(null);
                    }}
                  >
                    Limpiar
                  </Button>
                </Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "rgba(76, 78, 100, 0.68)",
                  }}
                >
                  Formatos permitidos JPG, GIF o PNG. Tamaño maximo 800K
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 1, marginBottom: "24px" }}>
              <Box sx={{ width: 300, height: 300, marginRight: "20px" }}>
                { ShowVideo() }
              </Box>

              <Box
                sx={{
                  marginBottom: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Box>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "secondary.dark",
                      marginRight: "16px",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      "&:hover": {
                        backgroundColor: "rgba(243, 236, 70, 0.95)",
                      },
                    }}
                  >
                    Subir Video
                    <input
                      hidden
                      accept="video/*"
                      type="file"
                      onChange={(e) => {
                        
                        dispatch(setCleanVideo())
                        setFile(null)
                        setFile(e.target.files[0]);
                      }}
                    />
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      border: "1px solid rgba(255, 77, 73, 0.5)",
                      color: "rgba(76, 78, 100, 0.87)",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      "&:hover": { borderColor: "primary.main" },
                    }}
                    onClick={(e) => {
                      dispatch(setCleanVideo());
                      setFile(null);
                    }}
                  >
                    Limpiar
                  </Button>
                </Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "rgba(76, 78, 100, 0.68)",
                  }}
                >
                  Formatos permitidos GIF o MP4. Tamaño maximo 800K
                </Typography>
              </Box>
            </Box>

            <div>
              <TextField
                id="outlined-basic"
                label="Nombre Guia"
                variant="outlined"
                value={guide.name}
                onChange={(e) => {
                  dispatch(
                    setGuide({
                      type: "name",
                      value: e.target.value,
                    })
                  );
                }}
              />
              <TextField
                id="outlined-select-currency"
                select
                label="Dificultad"
                helperText="Por favor seleccione el nivel de dificultad"
                value={guide.level}
                onChange={(e) => {
                  dispatch(
                    setGuide({
                      type: "level",
                      value: e.target.value,
                    })
                  );
                }}
              >
                {Levels.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div>
              <TextField
                id="outlined-select-currency"
                select
                label="Maquinas"
                helperText="Por favor seleccione una Maquina"
                value={guide.machine}
                onChange={(e) => {
                  dispatch(
                    setGuide({
                      type: "machine",
                      value: e.target.value,
                    })
                  );
                }}
              >
                {machines.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-currency"
                select
                label="Grupo Muscular"
                helperText="Por favor seleccione un grupo Muscular"
                value={guide.group}
                onChange={(e) => {
                  dispatch(
                    setGuide({
                      type: "group",
                      value: e.target.value,
                    })
                  );
                }}
              >
                {GrupoMuscular.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "15px",
                  color: "rgba(76, 78, 100, 0.68)",
                  marginY: "12px",
                  letterSpacing: "0.4px",
                }}
              >
                Descripción
              </Typography>

              <TextField
                variant="outlined"
                placeholder="Ingresa aquí tu descripción..."
                multiline
                rows={5}
                maxRows={10}
                sx={{
                  width: "91ch",
                  marginLeft: "10px",
                  borderRadius: "6px",
                }}
                name="Outlined"
                value={guide.description}
                onChange={(e) => {
                  dispatch(
                    setGuide({
                      type: "description",
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "primary.main",
                color: "secondary.dark",
                marginRight: "16px",
                "&:hover": { backgroundColor: "rgba(243, 236, 70, 0.95)" },
              }}
              onClick={(e) => {
                registerGuides();
              }}
            >
              {update ? "Actualizar" : "Registrar"}
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "rgba(76, 78, 100, 0.87)",
                border: "1px solid rgba(109, 120, 141, 0.5)",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                "&:hover": { borderColor: "primary.main" },
              }}
              onClick={(e) => {
                dispatch(setClean());
              }}
            >
              Cancelar
            </Button>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Principiante" {...a11yProps(0)} />
              <Tab label="Intermedio" {...a11yProps(1)} />
              <Tab label="Avanzado" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead sx={{ backgroundColor: "primary.main" }}>
                  <TableRow>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Nombre</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Nivel</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Maquina</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                      Grupo Muscular
                    </StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Descripción</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Acciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guidesEasy.map((guide) => (
                    <StyledTableRow>
                      <StyledTableCell align="right">
                        {guide.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {findLevel(guide.level)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {findMachineById(guide.machine.id)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {findGroupMuscle(guide.group)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <CustomWidthTooltip title={longText}>
                          <Button
                            sx={{
                              color: "secondary.dark",
                              textTransform: "capitalize",
                            }}
                          >
                            {guide.description}
                          </Button>
                        </CustomWidthTooltip>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Box>
                          <IconButton
                            aria-label="edit"
                            onClick={(e) => {
                              handlerUpdate(guide);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              deleteGuide(guide.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton aria-label="before">
                <NavigateBeforeOutlinedIcon fontSize="large" />
              </IconButton>
              <IconButton aria-label="next">
                <NavigateNextOutlinedIcon fontSize="large" />
              </IconButton>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead sx={{ backgroundColor: "primary.main" }}>
                  <TableRow>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Nombre</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Nivel</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Maquina</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                      Grupo Muscular
                    </StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Descripción</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Acciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guidesMedium.map((guide) => (
                    <StyledTableRow>
                      <StyledTableCell align="right">
                        {guide.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {findLevel(guide.level)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {findMachineById(guide.machine.id)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {findGroupMuscle(guide.group)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <CustomWidthTooltip title={longText}>
                          <Button
                            sx={{
                              color: "secondary.dark",
                              textTransform: "capitalize",
                            }}
                          >
                            {guide.description}
                          </Button>
                        </CustomWidthTooltip>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Box>
                          <IconButton
                            aria-label="edit"
                            onClick={(e) => {
                              handlerUpdate(guide);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              deleteGuide(guide.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton aria-label="before">
                <NavigateBeforeOutlinedIcon fontSize="large" />
              </IconButton>
              <IconButton aria-label="next">
                <NavigateNextOutlinedIcon fontSize="large" />
              </IconButton>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead sx={{ backgroundColor: "primary.main" }}>
                  <TableRow>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Nombre</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Nivel</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Maquina</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                      Grupo Muscular
                    </StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Descripción</StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontWeight: 700 }}>Acciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guidesHard.map((guide) => (
                    <StyledTableRow>
                      <StyledTableCell align="right">
                        {guide.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {findLevel(guide.level)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {findMachineById(guide.machine.id)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {findGroupMuscle(guide.group)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <CustomWidthTooltip title={longText}>
                          <Button
                            sx={{
                              color: "secondary.dark",
                              textTransform: "capitalize",
                            }}
                          >
                            {guide.description}
                          </Button>
                        </CustomWidthTooltip>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Box>
                          <IconButton
                            aria-label="edit"
                            onClick={(e) => {
                              handlerUpdate(guide);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              deleteGuide(guide.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton aria-label="before">
                <NavigateBeforeOutlinedIcon fontSize="large" />
              </IconButton>
              <IconButton aria-label="next">
                <NavigateNextOutlinedIcon fontSize="large" />
              </IconButton>
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(Guides);
