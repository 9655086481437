import React from "react";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Background from "../../assets/background.png";
import Logo from "../../assets/logoIronBody.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "./reducer/auth.reducer";
import { auth, db } from "../../infra/fifebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { setAuth, setType } from "./reducer/auth.reducer"
import { useNavigate } from "react-router-dom"


import {
  getFirestore,
  setDoc,
  doc,
  deleteDoc,
  where,
  limit,
  startAt,
  endAt,
  query,
  collection,
  getDoc,
  getDocs,
  updateDoc,
  orderBy,
  startAfter,
  endBefore,
} from "firebase/firestore";
const Auth = () => {
  const { user } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const history = useNavigate()

  const [showPassword, setShowPassword] = React.useState(false);

  

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const login = async () => {
    try {
      debugger
      const result = await signInWithEmailAndPassword(
        auth,
        user.correo,
        user.clave
      );
      
      
      const q = query( collection(db,"trainer"),where("uid","==",result.user.uid))
      const querySnapShot = await getDocs(q);
      let userDoc = null;
      querySnapShot.forEach((doc) => {
        userDoc=doc.data();
      });
      if(!userDoc){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'usuario no permitio',
        })
        return
      }
       dispatch(setAuth())
      dispatch(setType({
        type:userDoc.type.label
      }))
       localStorage.setItem("auth",true)
       history("/dashboard")
    } catch (e) {
      console.log(e.message);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'correo o contraseña incorrectos',
      })
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
      }}
    >
      <Card
        variant="outlined"
        sx={{ margin: "auto", justifyContent: "center", borderRadius: "10px" }}
      >
        <CardContent
          sx={{ display: "flex", flexDirection: "column", width: "380px" }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={Logo} height="65px" width="145px" alt="Logo IronBody" />
          </Box>

          <Box
            mt={"20px"}
            mb={"24px"}
            sx={{ alignContent: "center", justifyContent: "center" }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "25px",
                lineHeight: "150%",
                color: "rgba(76, 78, 100, 0.87)",
              }}
            >
              Bienvenido a IronBodyNeiva!
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "150%",
                color: "rgba(76, 78, 100, 0.68)",
              }}
            >
              Inicie sesión en su cuenta y comience la aventura
            </Typography>
          </Box>

          <Box>
            <TextField
              id="outlined-basic"
              label="Correo Electrónico"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: "16px" }}
              value={user.correo}
              onChange={(e) => {
                dispatch(
                  setUser({
                    type: "correo",
                    value: e.target.value,
                  })
                );
              }}
            />

            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Clave
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={user.clave}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "clave",
                      value: e.target.value,
                    })
                  );
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Clave"
              />
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Recuérdame"
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "150%",
                color: "rgba(76, 78, 100, 0.87)",
              }}
            />
            <Button
              size="small"
              href="/resetpassword"
              sx={{
                textTransform: "capitalize",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "150%",
                color: "rgba(76, 78, 100, 0.87)",
              }}
            >
              Contraseña Olvidada?
            </Button>
          </Box>

          <Button
            onClick={() => {
              login();
            }}
            variant="contained"
            sx={{
              backgroundColor: "#F3EC46",
              color: "#000",
              "&:hover": { backgroundColor: "rgba(243, 236, 70, 0.95)" },
            }}
          >
            Iniciar Sesión
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Auth;
