import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow } from "../styles/ListCustomer.styles";
import { Typography, Box, TextField, Button, MenuItem } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { v1 as uuidv1 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import {
  setWorkout,
  setClean,
  setWorkoutComplete,
  setCleanItemWorkout,
  setWorkoutItem,
  setUpdate,
  setGruposMusculares,
  setWorkoutItemStep,
  setIndex,
  setDescriptionDay,
  setNameDay
} from "../reducer/workout.reducer";
import {
  auth,
  storage,
  uploadBytes,
  setDoc,
  db,
  doc,
  getQuery,
  getDownloadURL,
  updateDoc,
  deleteDoc,
} from "../../infra/fifebase";
import { Timestamp } from "firebase/firestore";

const Workoutmodal = ({ uid }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [selectedDateStart, setSelectedDateStart] = React.useState(null);
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(null);

  const handleDateChangeStart = (date) => {
    setSelectedDateStart(date);
  };

  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
  };

  const addItemWorkout = () => {
    const {
      workout_name,
      group_muscle,
      guide,
      description,
      series,
      repetitions,
      rest,
      duration,
      guide_name
    } = workout.workoutItems[workout.index];
    const workoutItem = {
      workout_name,
      group_muscle,
      guide,
      description,
      series,
      repetitions,
      rest,
      duration,
      guide_name
    };
    
    dispatch(
      setWorkoutItemStep({
        type: "steps",
        index: workout.index,
        value: workoutItem,
      })
    );
    dispatch(setCleanItemWorkout(workout.index));
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    findMuscle(null, null);
  }, []);

  const findMuscle = async (action, where) => {
    
    const muscle = await getQuery("groupmuscle", action, where, {
      isStart: true,
      order: "name"
    });
    dispatch(setGruposMusculares(muscle));
  };


  const getNameMuscle = (id) =>{
     const grupo = GrupoMuscular.filter((grupo)=> grupo.id === id)
       
     return grupo[0].name
  }


  const findGuidesForGroup = async (index, id) => {
    const guide = await getQuery("guide", null, [
      {
        item: "group",
        condition: "==",
        value: id,
      },
    ], {
      isStart: true,
      order: "name"
    });
    dispatch(
      setWorkoutItem({
        type: "guides",
        index,
        value: guide,
      })
    );
  };



  const registerWorkout = async () => {
    try {
      if (!update) {
        const id = uuidv1();

        const workoutsSteps = workout.workoutItems.map((workout, index) => {
          const steps = workout.steps.map((stepInternal) => {
            const guide = doc(db, "guide", stepInternal.guide);
            const step = {
              ...stepInternal,
              guide,
            };

            return step;
          });
          return {
            day: index,
            name: nameDay[index] ?? "",
            description: descriptionDay[index] ?? "",
            steps,
          };
        });

        await setDoc(doc(db, "workout", id), {
          id,
          date_start: Timestamp.fromDate(
            new Date(selectedDateStart.toISOString())
          ),
          date_end: Timestamp.fromDate(new Date(selectedDateEnd.toISOString())),
          height: workout.height,
          weight: workout.weight,
          fat: workout.fat,
          imc: workout.imc,
          icc: workout.icc,
          recommendations: workout.recommendations,
          steps: workoutsSteps,
          uid_user: uid,
          active: true,
        });
        Swal.fire("hecho", "Rutina registrada exitosamente", "success");
        setUpdate(false);
        dispatch(setClean());
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { workout, update, GrupoMuscular, descriptionDay, nameDay } = useSelector(
    (state) => state.workout
  );

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "32ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <Box sx={{ marginBottom: "12px" }}>
        <Typography variant="h5">Rutinas</Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "15px",
            color: "rgba(76, 78, 100, 0.68)",
            marginBottom: "12px",
            letterSpacing: "0.4px",
          }}
        >
          Completa todo los campos para crear una Rutina
        </Typography>
      </Box>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Fecha de Valoración"
              onChange={handleDateChangeStart}
              value={selectedDateStart}
            />
            <DatePicker
              label="Fecha Proxima Valoración"
              onChange={handleDateChangeEnd}
              value={selectedDateEnd}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>

      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "15px",
          color: "rgba(76, 78, 100, 0.68)",
          marginY: "12px",
          letterSpacing: "0.4px",
        }}
      >
        Datos Actuales
      </Typography>
      <div>
        <TextField
          id="outlined-basic"
          label="Talla (m)"
          variant="outlined"
          onChange={(e) => {
            dispatch(
              setWorkout({
                type: "height",
                value: e.target.value,
              })
            );
          }}
          value={workout.height}
        />
        <TextField
          id="outlined-basic"
          label="Peso (kg)"
          variant="outlined"
          onChange={(e) => {
            dispatch(
              setWorkout({
                type: "weight",
                value: e.target.value,
              })
            );
          }}
          value={workout.weight}
        />
      </div>
      <div>
        <TextField
          id="outlined-basic"
          label="% Grasa"
          variant="outlined"
          onChange={(e) => {
            dispatch(
              setWorkout({
                type: "fat",
                value: e.target.value,
              })
            );
          }}
          value={workout.fat}
        />
        <TextField
          id="outlined-basic"
          label="IMC"
          variant="outlined"
          onChange={(e) => {
            dispatch(
              setWorkout({
                type: "imc",
                value: e.target.value,
              })
            );
          }}
          value={workout.imc}
        />
      </div>
      <div>
        <TextField
          id="outlined-basic"
          label="ICC"
          variant="outlined"
          onChange={(e) => {
            dispatch(
              setWorkout({
                type: "icc",
                value: e.target.value,
              })
            );
          }}
          value={workout.icc}
        />
      </div>



      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "15px",
          color: "rgba(76, 78, 100, 0.68)",
          marginY: "12px",
          letterSpacing: "0.4px",
        }}
      >
        Recomendaciones
      </Typography>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "66ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            variant="outlined"
            placeholder="Ingresa aquí tu mensaje..."
            multiline
            rows={4}
            maxRows={10}
            sx={{ marginLeft: "10px", borderRadius: "6px" }}
            name="Outlined"
            onChange={(e) => {
              dispatch(
                setWorkout({
                  type: "recommendations",
                  value: e.target.value,
                })
              );
            }}
            value={workout.recommendations}
          />
        </div>
      </Box>

      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "15px",
          color: "rgba(76, 78, 100, 0.68)",
          marginY: "12px",
          letterSpacing: "0.4px",
        }}
      >
        Rutina por díaS
      </Typography>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>

          <TextField
            id="outlined-select-currency"
            select
            label="Elegir dia"
            helperText="Selecciona un dia de entrenamiento"
            value={workout.index}
            onChange={(e) => {
              dispatch(setIndex(e.target.value));
            }}
          >
            {workout.workoutItems.map((tab, index) => (
              <MenuItem key={index} value={index}>
                {"Día " + (index + 1)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="outlined-basic"
            label="nombre del dia"
            variant="outlined"
            value={nameDay[workout.index] ?? ""}
            onChange={(e) => {
              dispatch(
                setNameDay({
                  index: workout.index,
                  value: e.target.value
                })
              );
            }}
          />
          <Box sx={{
            "& .MuiTextField-root": { m: 1, width: "65ch" },
          }}
            noValidate
            autoComplete="off">
            
              <TextField
                id="outlined-basic"
                label="descripcion del dia"
                variant="outlined"
                placeholder="Ingresa una descripcion general del día..."
                multiline
                rows={4}
                
                value={descriptionDay[workout.index] ?? ""}
                onChange={(e) => {
                  dispatch(
                    setDescriptionDay({
                      index: workout.index,
                      value: e.target.value
                    })
                  );
                }}
              />
            


          </Box>
        </Box>


        <Box
          sx={{
            "& .MuiTextField-root": { m: 1, width: "30ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              id="outlined-basic"
              label="Nombre ejercicio"
              variant="outlined"
              value={workout.workoutItems[workout.index].workout_name}
              onChange={(e) => {
                dispatch(
                  setWorkoutItem({
                    type: "workout_name",
                    index: workout.index,
                    value: e.target.value,
                  })
                );
              }}
            />
            <TextField
              id="outlined-select-currency"
              select
              label="Grupo Muscular"
              helperText="Selecciona un grupo muscular"
              value={workout.workoutItems[workout.index].group_muscle}
              onChange={(e) => {
                findGuidesForGroup(workout.index, e.target.value);
                dispatch(
                  setWorkoutItem({
                    type: "group_muscle",
                    index: workout.index,
                    value: e.target.value,
                  })
                );
              }}
            >
              {GrupoMuscular.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div>
            <TextField
              id="outlined-select-currency"
              select
              label="Guías asociadas"
              helperText="Selecciona una Guía"
              value={workout.workoutItems[workout.index].guide}
              onChange={(e) => {
                
                const guide =workout.workoutItems[workout.index].guides.filter((guide)=>guide.id ===  e.target.value)
                const guide_name= guide[0].name
                dispatch(
                  setWorkoutItem({
                    type: "guide",
                    index: workout.index,
                    guide: e.target.value,
                    guide_name
                  })
                );
                
              }}
            >
              {workout.workoutItems[workout.index].guides.map((option) => (
                <MenuItem key={option.value} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="outlined-basic"
              label="Duración total ejercicio"
              variant="outlined"
              value={workout.workoutItems[workout.index].duration}
              onChange={(e) => {
                dispatch(
                  setWorkoutItem({
                    type: "duration",
                    index: workout.index,
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>

          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "64ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                variant="outlined"
                placeholder="Ingresa una descripcion del ejercicio a realizar..."
                multiline
                rows={4}
                maxRows={10}
                sx={{ marginLeft: "10px", borderRadius: "6px" }}
                name="Outlined"
                value={workout.workoutItems[workout.index].description}
                onChange={(e) => {
                  dispatch(
                    setWorkoutItem({
                      type: "description",
                      index: workout.index,
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>
          </Box>

          <Box
            sx={{
              "& .MuiTextField-root": { m: 0.6, width: "20ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="outlined-basic"
                label="series"
                variant="outlined"
                value={workout.workoutItems[workout.index].series}
                onChange={(e) => {
                  dispatch(
                    setWorkoutItem({
                      type: "series",
                      index: workout.index,
                      value: e.target.value,
                    })
                  );
                }}
              />
              <TextField
                id="outlined-basic"
                label="Repeticiones"
                variant="outlined"
                value={workout.workoutItems[workout.index].repetitions}
                onChange={(e) => {
                  dispatch(
                    setWorkoutItem({
                      type: "repetitions",
                      index: workout.index,
                      value: e.target.value,
                    })
                  );
                }}
              />

              <TextField
                id="outlined-basic"
                label="Descanso"
                variant="outlined"
                value={workout.workoutItems[workout.index].rest}
                onChange={(e) => {
                  dispatch(
                    setWorkoutItem({
                      type: "rest",
                      index: workout.index,
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>
          </Box>
        </Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.main",
            color: "secondary.dark",
            m: 1,
            "&:hover": { backgroundColor: "rgba(243, 236, 70, 0.95)" },
          }}
          onClick={() => {
            addItemWorkout();
          }}
        >
          Agregar
        </Button>

        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: "primary.main" }}>
              <TableRow>
                <StyledTableCell sx={{ fontWeight: 700 }}>
                  Nombre
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 700 }}>
                  Grupo Muscular
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 700 }}>Guía</StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 700 }}>
                  descripcion
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 700 }}>
                  Series
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 700 }}>
                  Repeticiones
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 700 }}>
                  Descanso
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workout.workoutItems[workout.index].steps.map((step) => (
                <StyledTableRow>
                  <StyledTableCell align="left">
                    {step.workout_name}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {getNameMuscle(step.group_muscle)}
                  </StyledTableCell>

                  <StyledTableCell align="left">{step.guide_name}</StyledTableCell>

                  <StyledTableCell align="left">
                    {step.description}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {step.series}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {step.repetitions}
                  </StyledTableCell>
                  <StyledTableCell align="center">{step.rest}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              color: "secondary.dark",
              marginTop: "12px",
              "&:hover": { backgroundColor: "rgba(243, 236, 70, 0.95)" },
            }}
            onClick={(e) => {
              registerWorkout();
            }}
          >
            Asignar Rutina
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Workoutmodal;
