import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice(
  {
    name: 'login',
    initialState: {
        user: {
          correo:"",
          clave:""
        },
        auth:localStorage.getItem("auth") !== null,
        type:""
    },
    reducers: {
        setUser: (state, action) => {
          state.user[action.payload.type]= action.payload.value
        },
        setType:(state,action) =>{
          state.type = action.payload.type
        },
        setAuth :(state,action)=>{
          state.auth=true
        },
        setLogout :(state,action)=>{
          state.auth=false
        }
    }
}
) 



export const {
  setUser,
  setAuth,
  setLogout,
  setType
} =loginSlice.actions


export default loginSlice.reducer