import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { ref, getStorage, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  setDoc,
  doc,
  deleteDoc,
  where,
  limit,
  startAt,
  endAt,
  query,
  collection,
  getDoc,
  getDocs,
  updateDoc,
  orderBy,
  startAfter,
  endBefore,
} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDsp_gAoXusG3xDeBqcAs1Wvht4mq35m_c",
  authDomain: "iron-body-3dcfd.firebaseapp.com",
  projectId: "iron-body-3dcfd",
  storageBucket: "iron-body-3dcfd.appspot.com",
  messagingSenderId: "159664010513",
  appId: "1:159664010513:web:eba94aa2cf1ad15cf6c8b0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

// Create a storage reference from our storage service
const storage = (state) => {
  return ref(getStorage(), state);
};

const getQuery = async (ref, cursor, whereCon, { isStart = true, order=null , state = false}, limitTo=200) => {
  var builder = [];


  if (whereCon) {
    for (let index = 0; index < whereCon.length; index++) {
      builder.push(
        where(
          whereCon[index].item,
          whereCon[index].condition,
          whereCon[index].value
        )
      );
    }
  }
  var asc = isStart ? "asc" : "asc";
  if(state){
    builder.push(orderBy("state",asc))
  }
  builder.push(orderBy(order,asc));
  if(cursor){
    if(isStart){
      builder.push(startAfter(cursor[order]));
    }else{
  
      builder.push(endAt(cursor[order]));
    }
  }
  builder.push(limit(limitTo));

 

  const q = query(collection(db, ref), ...builder);

  const querySnapShot = await getDocs(q);
  var data = [];
  querySnapShot.forEach((doc) => {
    data.push(doc.data());
  });

  return data;
};

const findLimit = (data) => {
  const limitStart = data[0];
  const limitEnd = data[data.length - 1];
  return {
    limitStart,
    limitEnd,
  };
};

export {
  auth,
  storage,
  uploadBytes,
  db,
  deleteDoc,
  doc,
  getQuery,
  setDoc,
  getDownloadURL,
  updateDoc,
  getDocs,
  query,
  getDoc,
  findLimit,
};
