import React from "react";
import { Routes, Route } from "react-router-dom";

// import { Link } from 'react-router-dom'
import TemporaryDrawer from "../../components/TemporaryDrawer";
import RegisterForm from "../../components/RegisterForm";
import Calendar from "../../components/Calendar";
import ListCustomer from "../../components/ListCustomer";
import Guides from "../../components/Guides";
import Notifications from "../../components/Notifications";
import WorkoutMachine from "../../components/WorkoutMachine";
// import WorkoutForm from "../../components/WorkoutForm";

const Home = () => {
  return (
    <main>
      <TemporaryDrawer>
        <Routes>
          <Route path="/clientes" element={<ListCustomer />} />
          {/* <Route path="/rutinas" element={<WorkoutForm />} /> */}
          <Route path="/notificaciones" element={<Notifications />} />
          <Route path="/guias" element={<Guides />} />
          <Route path="/calendario" element={<Calendar />} />
          <Route path="/entrenadores" element={<RegisterForm />} />
          <Route path="/maquinas" element={<WorkoutMachine />} />
        </Routes>
      </TemporaryDrawer>
    </main>
  );
};

export default Home;
