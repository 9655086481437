import { createSlice } from "@reduxjs/toolkit";

export const registerSlice = createSlice(
  {
    name: 'register',
    initialState: {
      user: {
        email: "",
        password: "",
        confirm_password: "",
        type: "",
        url_image: "",
        name: "",
        last_name: "",
        phone: "", 
        address: "",
        state: "",
        year: "",
        description:"",
      },
      users:[],
      update:false
    },
    reducers: {
      setUpdate : (state,action) =>{
        state.update= action.payload
      },
      setUsers :(state,action) =>{
        state.users = action.payload
      },
      setCleanImage: (state, action) =>{
        state.user.url_image =""
    },
      setUser: (state, action) => {
        state.user[action.payload.type] = action.payload.value
      },
      setUserComplete : (state,action) =>{
        state.user = action.payload
      },
      setClean: (state, action) => {
        state.user = {
          email: "",
          password: "",
          confirm_password: "",
          type: "",
          url_image: "",
          name: "",
          last_name: "",
          phone: "",
          address: "",
          state: "",
          year: "",
          description:"",
        }
      }
    }
  }
)



export const {
  setUser,
  setClean,
  setPage,
  setUsers,
  setUserComplete,
  setUpdate,
  setCleanImage
} = registerSlice.actions


export default registerSlice.reducer