import { useState, useEffect, memo } from "react";
import FullCalendar from "@fullcalendar/react";
import { formatDate } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import esLocale from "@fullcalendar/core/locales/es";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { v1 as uuidv1 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { setCalendars, setTrainer } from "./reducer/calendar.reducer";
import {
  auth,
  storage,
  uploadBytes,
  setDoc,
  db,
  doc,
  getQuery,
  getDownloadURL,
  updateDoc,
  deleteDoc,
} from "../infra/fifebase";
import Swal from "sweetalert2";
import { Timestamp } from "firebase/firestore";

const Calendar = () => {
  var contentStatus = [
    {
      value: "in_progress",
      text: "En progreso",
    },
    {
      value: "completed",
      text: "Terminado",
    },
    {
      value: "cancelled",
      text: "Cancelados",
    },
  ];

  const dispatch = useDispatch();

  const { calendars, trainer } = useSelector((state) => state.calendar);

  const [status, setStatus] = useState("in_progress");

  useEffect(() => {
    findCalendars(null, [
      {
        item: "statu",
        condition: "==",
        value: status,
      },
    ]);
  }, []);

  const findCalendars = async (action, where) => {
    const calendar = await getQuery("event", action, where, {
      isStart: true,
      order: "id"
    });
    const trainer = await getQuery("trainer", null, null, {
      isStart: true,
      order: "uid"
    });

    const dataCalendar = calendar.map((cal) => {
      return {
        id: cal.id,
        title: cal.title,
        start: cal.start.toDate().toISOString(),
        end: cal.end.toDate().toISOString(),
        allDay: cal.allDay,
        statu: "in_progress",
        numberUsers: cal.numberUsers,
        listUser:cal.listUser
      };
    });
    dispatch(setTrainer(trainer));
    dispatch(setCalendars(dataCalendar));
  };

  const handleDateClick = async (selected) => {
    let html =
      '<input id="name" class="swal2-input" placeholder="Nombre">' +
      '<input id="numberusers" class="swal2-input" placeholder="Numero personas">' +
      '<select id="trainer" class="swal2-select">';
    console.log(trainer);
    const optionTrainer = trainer.map((tra) => {
      return `<option value="${tra.uid}">${tra.name} ${tra.last_name}</option>`;
    });
    html += optionTrainer.join();
    html += "</select>";

    console.log(html);

    Swal.fire({
      title: "Ingrese nombre del evento",
      html,
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      preConfirm: () => {
        const name = Swal.getPopup().querySelector("#name").value;
        const numberUsers = Swal.getPopup().querySelector("#numberusers").value;
        const trainer = Swal.getPopup().querySelector("#trainer").value;
        return { name, trainer, numberUsers };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const id = uuidv1();
        const { trainer, name, numberUsers } = result.value;
        const event = {
          id,
          title: name,
          start: Timestamp.fromDate(selected.start),
          end: Timestamp.fromDate(selected.end),
          allDay: selected.allDay,
          statu: "in_progress",
          uidTrainer: doc(db, "trainer", trainer),
          listUser: [],
          numberUsers,
        };

        await setDoc(doc(db, "event", id), event);
        Swal.fire("hecho", "guias registrada exitosamente", "success");
        findCalendars(null, [
          {
            item: "statu",
            condition: "==",
            value: status,
          },
        ]);
        const calendarApi = selected.view.calendar;
        calendarApi.addEvent(event);
        calendarApi.unselect();
      }
    });
  };

  const handleEventClick = (selected) => {
    Swal.fire({
      title: `que quieres hacer con este evento '${selected.event.title}'`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Terminarlo",
      denyButtonText: `Eliminarlo`,
      cancelButtonText: `Cancelarlo`,
    })
      .then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          selected.event.status = "completed";
          try {
            const response = await updateDoc(
              doc(db, "event", selected.event.id),
              {
                statu: "completed",
                title: selected.event.title,
                start: Timestamp.fromDate(selected.event.start),
                end: Timestamp.fromDate(selected.event.end),
                allDay: selected.event.allDay,
              }
            );
            console.log(response);
            Swal.fire("terminado!", "El evento ha sido terminado", "success");
          } catch (e) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: e.message,
              footer: "",
            });
          }
          return;
        }

        if (result.isDenied) {
          try {
            await deleteDoc(doc(db, "event", selected.event.id));
            Swal.fire("Eliminado!", "El evento ha sido eliminado", "success");
          } catch (e) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: e.message,
              footer: "",
            });
          }
          return;
        }

        selected.event.status = "cancelled";
        try {
          await updateDoc(doc(db, "event", selected.event.id), {
            statu: "cancelled",
            title: selected.event.title,
            start: Timestamp.fromDate(selected.event.start),
            end: Timestamp.fromDate(selected.event.end),
            allDay: selected.event.allDay,
          });
          Swal.fire("Cancelado!", "El evento ha sido cancelado", "success");
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: e.message,
            footer: "",
          });
        }
      })
      .finally(() => {
        selected.event.remove();
        findCalendars(null, [
          {
            item: "statu",
            condition: "==",
            value: status,
          },
        ]);
      });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR SIDEBAR */}
        <Box
          flex="1 1 20%"
          // p="15px"
          borderRadius="10px"
          sx={{}}
        >
          <Box
            bgcolor={"primary.main"}
            sx={{
              boxShadow: "0px 4px 8px -4px rgba(76, 78, 100, 0.42)",
              borderRadius: "8px",
              padding: "9px",
              textAlign: "center",
              width: "0 auto",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                width: "0 auto",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Eventos
            </Typography>
          </Box>
          <FormControl
            fullWidth
            sx={{
              marginTop: 3,
            }}
          >
            <InputLabel id="demo-simple-select-label">
              Estado agenda
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Age"
              onChange={(e) => {
                setStatus(e.target.value);
                findCalendars(null, [
                  {
                    item: "statu",
                    condition: "==",
                    value: e.target.value,
                  },
                ]);
              }}
            >
              {contentStatus.map((content) => (
                <MenuItem value={content.value}>{content.text}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <List>
            {calendars.map((event) => (
              <ListItem
                key={event.id}
                sx={{
                  borderRadius: "2px",
                }}
              >
                <ListItemText
                  primary={event.title}
                  secondary={
                    <>
                      <Typography>
                        {formatDate(event.start, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </Typography>
                      <Typography>{event.listUser.length}/{event.numberUsers}</Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* CALENDAR */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            locale={esLocale}
            height="80vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            eventClick={handleEventClick}
            eventsSet={(events) => {}}
            events={calendars}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default memo(Calendar);
