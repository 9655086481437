import { createSlice } from "@reduxjs/toolkit";

export const historySlice = createSlice({
  name: "history",
  initialState: {
    history: {
      year: "",
      month: "",
      weight: "",
      fat: "",
      imc: "",
      icc: "",
    },
    histories: [],
    filters: [],
    historyWeights: [],
    historyFats: [],
    historyImcs: [],
    historyIccs: [],
  },
  reducers: {
    setHistory: (state, action) => {
      state.history[action.payload.type] = action.payload.value;
    },
    setClean: (state, action) => {
      state.history = {
        year: "",
        month: "",
        weight: "",
        fat: "",
        imc: "",
        icc: "",
      };
    },
    setHistories: (state, action) => {
      state.histories = action.payload;
    },
    setHistoryWeights: (state, action) => {
      state.historyWeights = action.payload;
    },
    setHistoryFats: (state, action) => {
      state.historyFats = action.payload;
    },
    setHistoryImcs: (state, action) => {
      state.historyImcs = action.payload;
    },
    setHistoryIccs: (state, action) => {
      state.historyIccs = action.payload;
    },
  },
});

export const {
  setHistory,
  setClean,
  setHistories,
  setHistoryWeights,
  setHistoryFats,
  setHistoryImcs,
  setHistoryIccs,
} = historySlice.actions;

export default historySlice.reducer;
