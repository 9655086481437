import React, { memo } from "react";
import { TextField, Box, MenuItem, Button, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import imgUser from "../assets/Avatar.png";
import { useSelector, useDispatch } from "react-redux";
import { createUserWithEmailAndPassword } from "firebase/auth";
import Swal from "sweetalert2";
import { v1 as uuidv1 } from "uuid";

import {
  setUser,
  setClean,
  setUsers,
  setUserComplete,
  setUpdate,
  setCleanImage,
} from "./reducer/register.reducer";
import {
  auth,
  storage,
  uploadBytes,
  setDoc,
  db,
  doc,
  getQuery,
  getDownloadURL,
  updateDoc,
  deleteDoc,
} from "../infra/fifebase";
import { StyledTableCell, StyledTableRow } from "./styles/RegisterForm.styles";

const RegisterForm = () => {
  const { user, users, update } = useSelector((state) => state.register);
  const dispatch = useDispatch();
  const [file, setFile] = React.useState(null);

  const findTrainer = async (action, where) => {
    
    const trainer = await getQuery("trainer", action, where,{
      isStart:true,
      order:"name"
    });
    dispatch(setUsers(trainer));
  };

  React.useEffect(() => {
    findTrainer(null, null);
  }, []);
  const registerTrainer = async () => {
    try {
      const { email, name, last_name, address, phone, year , description } = user;
     var score = user.score ?? 0
     var review = user.review ?? 0

      const type = Rol.find((item) => item.value === user.type);

      if (!update) {
        if (user.password !== user.confirm_password) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "claves no coinciden",
            footer: "",
          });
          return;
        }

        if (user.password.length < 6) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "clave mayor a 6 digitos",
            footer: "",
          });
          return;
        }
        const result = await createUserWithEmailAndPassword(
          auth,
          user.email,
          user.password
        );
        const snapshot = await uploadBytes(
          storage("trainer/tr-" + result.user.uid + ".png"),
          file
        );

        const url_image = await getDownloadURL(
          storage(snapshot.metadata.fullPath)
        );

        await setDoc(doc(db, "trainer", result.user.uid), {
          email,
          name,
          last_name,
          address,
          phone,
          url_image,
          state: user.state ? "Activo" : "Inactivo",
          uid: result.user.uid,
          type,
          year,
          description,
          score,
          review
        });
        Swal.fire("hecho", "entrenador registrado exitosamente", "success");
      } else {
        let url_image = "";
        if (file) {
          const id = uuidv1();
          const snapshotImage = await uploadBytes(
            storage("trainer/tr-" + id + ".png"),
            file
          );
          url_image = await getDownloadURL(
            storage(snapshotImage.metadata.fullPath)
          );
        }

        await updateDoc(doc(db, "trainer", user.uid), {
          email,
          name,
          last_name,
          address,
          phone,
          url_image: file ? url_image : user.url_image,
          state: user.state ? "Activo" : "Inactivo",
          uid: user.uid,
          type,
          year,
          description,
          score,
          review
        });

        Swal.fire("hecho", "entrenador registrado exitosamente", "success");
      }
      setFile(null);
      setUpdate(false);
      dispatch(setClean());
      findTrainer(null, null);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.message,
        footer: "",
      });
    }
  };

  const handlerUpdate = async (user) => {
    const type = Rol.find((item) => item.label === user.type.label);
    console.log(type);
    dispatch(setUpdate(true));
    console.log(user.url_image);
    const url_image = await getDownloadURL(storage(user.url_image));
    console.log(url_image);
    dispatch(
      setUserComplete({
        ...user,
        type: type.value,
        state: user.state === "Activo" ? "1" : "0",
        url_image,
      })
    );
  };

  const deleteUser = async (id) => {
    try {
      Swal.fire({
        title: "Estas seguro",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const trainers = await getQuery("trainer", null, [
            {
              item: "trainer",
              condition: "==",
              value: id,
            },
          ],{
            isStart:true,
            order:"name"
          });

          if (trainers.length !== 0) {
            Swal.fire(
              "Lo sentimos",
              "No puedes eliminar esta maquina porque tiene guias asociadas",
              "question"
            );
            return;
          }

          await deleteDoc(doc(db, "trainer", id));
          findTrainer(null, null);
          Swal.fire("Eliminado!", "El entrenador ha sido eliminado", "success");
        }
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.message,
        footer: "",
      });
    }
  };

  const Rol = [
    {
      value: "2",
      label: "Entrenador",
    },
    {
      value: "3",
      label: "Administrador",
    },
  ];
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ marginBottom: "12px" }}>
          <Typography variant="h5">Listado Entrenador</Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "15px",
              color: "rgba(76, 78, 100, 0.68)",
              marginBottom: "12px",
              letterSpacing: "0.4px",
            }}
          >
            Administra la información de tus entrenadores
          </Typography>
        </Box>
      </Box>

      <Accordion sx={{ marginBottom: "12px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Registar Nuevo Entrenador</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "45ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Box sx={{ display: "flex", gap: 1, marginBottom: "24px" }}>
              <Box sx={{ width: 120, height: 120, marginRight: "20px" }}>
                <img
                  width={120}
                  height={120}
                  src={
                    !file
                      ? user.url_image === ""
                        ? imgUser
                        : user.url_image
                      : URL.createObjectURL(file)
                  }
                  alt="user"
                />
              </Box>

              <Box
                sx={{
                  marginBottom: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Box>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "secondary.dark",
                      marginRight: "16px",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      "&:hover": {
                        backgroundColor: "rgba(243, 236, 70, 0.95)",
                      },
                    }}
                  >
                    Subir Foto
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      border: "1px solid rgba(255, 77, 73, 0.5)",
                      color: "rgba(76, 78, 100, 0.87)",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      "&:hover": { borderColor: "primary.main" },
                    }}
                    onClick={(e) => {
                      dispatch(setCleanImage());
                      setFile(null);
                    }}
                  >
                    Limpiar
                  </Button>
                </Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "rgba(76, 78, 100, 0.68)",
                  }}
                >
                  Formatos permitidos JPG, GIF or PNG. Tamaño maximo 800K
                </Typography>
              </Box>
            </Box>

            <div>
              <TextField
                id="outlined-basic"
                label="Nombres"
                variant="outlined"
                value={user.name}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "name",
                      value: e.target.value,
                    })
                  );
                }}
              />
              <TextField
                id="outlined-basic"
                label="Apellidos"
                variant="outlined"
                value={user.last_name}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "last_name",
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>
            <div>
              <TextField
                id="outlined-basic"
                type={"email"}
                label="Correo Electrónico"
                variant="outlined"
                value={user.email}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "email",
                      value: e.target.value,
                    })
                  );
                }}
              />

              <TextField
                id="outlined-select-currency"
                select
                label="Rol"
                defaultValue="Cliente"
                helperText="Por favor seleccione un Rol"
                value={user.type}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "type",
                      value: e.target.value,
                    })
                  );
                }}
              >
                {Rol.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <TextField
                id="outlined-select-currency"
                select
                label="Estado"
                defaultValue="false"
                helperText="Por favor seleccione un Estado"
                value={user.state}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "state",
                      value: e.target.value,
                    })
                  );
                }}
              >
                <MenuItem key={1} value={1}>
                  Activo
                </MenuItem>
                <MenuItem key={0} value={0}>
                  Inactivo
                </MenuItem>
              </TextField>
              <TextField
                id="outlined-basic"
                label="Teléfono"
                variant="outlined"
                value={user.phone}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "phone",
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>
            <div>
              <TextField
                id="outlined-basic"
                label="años de Experiencia"
                variant="outlined"
                value={user.year}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "year",
                      value: e.target.value,
                    })
                  );
                }}
              />
              <TextField
                id="outlined-basic"
                label="Dirección"
                variant="outlined"
                value={user.address}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "address",
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>
            <div>
              <TextField
                id="outlined-password-input"
                label="Clave"
                type="password"
                autoComplete="current-password"
                value={user.password}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "password",
                      value: e.target.value,
                    })
                  );
                }}
              />
              <TextField
                id="outlined-password-input"
                label="Confirmar Clave"
                type="password"
                autoComplete="current-password"
                value={user.confirm_password}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "confirm_password",
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>

            <div>
            <TextField
                variant="outlined"
                placeholder="Ingresa aquí tu descripción..."
                multiline
                rows={5}
                maxRows={10}
                sx={{
                  width: "91ch",
                  marginLeft: "10px",
                  borderRadius: "6px",
                }}
                name="Outlined"
                value={user.description}
                onChange={(e) => {
                  dispatch(
                    setUser({
                      type: "description",
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "primary.main",
                color: "secondary.dark",
                marginRight: "16px",
                marginTop: "16px",
                "&:hover": { backgroundColor: "rgba(243, 236, 70, 0.95)" },
              }}
              onClick={(e) => {
                registerTrainer();
              }}
            >
              {update ? "Actualizar" : "Registrar"}
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "rgba(76, 78, 100, 0.87)",
                border: "1px solid rgba(109, 120, 141, 0.5)",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                marginTop: "16px",
                "&:hover": { borderColor: "primary.main" },
              }}
              onClick={(e) => {
                dispatch(setClean());
                setFile(null);
              }}
            >
              Limpiar
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: 700 }}>
                Nombres
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                Apellidos
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                Teléfono
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                Correo Eléctronico
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                Estado
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ fontWeight: 700 }}>
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {user.name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {user.last_name}
                </StyledTableCell>
                <StyledTableCell align="right">{user.phone}</StyledTableCell>
                <StyledTableCell align="right">{user.email}</StyledTableCell>
                <StyledTableCell align="right">{user.state}</StyledTableCell>
                <StyledTableCell align="center">
                  <Box>
                    <IconButton
                      aria-label="edit"
                      onClick={(e) => {
                        handlerUpdate(user);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        deleteUser(user.uid);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default memo(RegisterForm);
