import { createSlice } from "@reduxjs/toolkit";


export const guideSlice = createSlice({
    name: "guide",
    initialState: {
        guide: {
            name: "",
            level: "",
            machine: "",
            group: "",
            description: "",
            url_video: "",
            url_cover: ""
        },
        GrupoMuscular: [],
        machines: [],
        guidesMedium: [],
        guidesEasy: [],
        guidesHard: [],
        update: false
    },
    reducers: {
        setGuide: (state, action) => {
            state.guide[action.payload.type] = action.payload.value
        },
        setClean: (state, action) => {
            state.guide = {
                name: "",
                level: "",
                machine: "",
                group: "",
                description: "",
                url_video: "",
                url_cover: ""
            }
        },
        setCleanImage: (state, action) =>{
            state.guide.url_cover =""
        },
        setCleanVideo: (state, action) => {
            state.guide.url_video = ""
        },
        setMachines: (state, action) => {
            state.machines = action.payload
        },
        setGuideComplete: (state, action) => {
            state.guide = action.payload
        },
        setGuidesEasy: (state, action) => {
            state.guidesEasy = action.payload
        },
        setGuidesMedium: (state, action) => {
            state.guidesMedium = action.payload
        },
        setGuidesHard: (state, action) => {
            state.guidesHard= action.payload
        },
        setUpdate: (state, action) => {
            state.update = action.payload
        },
        setGruposMusculares: (state, action) => {
            state.GrupoMuscular = action.payload
        }
    }
})



export const {
    setGuide,
    setClean,
    setGuideComplete,
    setGuidesEasy,
    setGuidesMedium,
    setGuidesHard,
    setUpdate,
    setMachines,
    setGruposMusculares,
    setCleanImage,
    setCleanVideo
} = guideSlice.actions



export default guideSlice.reducer