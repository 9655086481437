import { createSlice } from "@reduxjs/toolkit";



export const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        notification: {
            type: [],
            message: "",
            user: "",
            url_cover:""
        }
    },
    reducers: {
        setNotification: (state, action) => {
            state.notification[action.payload.type] = action.payload.value
        },
        setClean: (state, action) => {
            state.notification = {
                type: [],
                message: "",
                user: "",
            }
        },
        setType : (state,action)=>{
            if(!action.payload.active){
                state.notification.type.push(action.payload.type)
                return
            }
           
            const index=state.notification.type.findIndex(type =>( type === action.payload.type))
            state.notification.type.splice(index,1)
        },
        setCleanImage: (state, action) =>{
            state.notification.url_cover =""
        },
    }
})


export const {
    setCleanImage,
    setNotification,
    setClean,
    setType
} = notificationSlice.actions



export default notificationSlice.reducer